import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SharingService } from "../services/sharing.service";

@Injectable({
    providedIn: 'root'
})

export class EndpointConfig {
    apiURL: string;

    constructor(private http: HttpClient, private svc: SharingService) {
        this.apiURL = this.svc.baseApiUrl();
    }

    get(url: string, params?: any): any {
        return this.http.get(`${this.apiURL}/${url}`, { params: params })
            .pipe(catchError(this.handleHttpErrors));
    }

    post(url: string, request: any) {
        return this.http.post(`${this.apiURL}/${url}`, request).pipe(catchError(this.handleHttpErrors));
    }

    delete(url: string, headers: any) {
        return this.http.delete(`${this.apiURL}/${url}`, { headers: headers })
            .pipe(catchError(this.handleHttpErrors));
    }

    put(url: string, request: any) {
        return this.http.put(`${this.apiURL}/${url}`, request).pipe(catchError(this.handleHttpErrors));
    }

    private handleHttpErrors(error: Response | any) {
        switch (error.status) {
            case 503 || 500:
                return throwError(error);
            case 401 || 403:   //unauthenticated
                return throwError(error);
            case 404:
                return throwError(error);
            default:
                break;
        }
    }
}