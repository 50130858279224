<div class="loader" *ngIf="loader">
    <div id="loading"></div>
</div>
<div class="container m-top" style="padding: 0 0 30px 0;">
    <div class="applicationProgress">
        <div class="appProgStep " style="z-index:5; padding-left: 30px;" id="app1">Demographics &amp; Dependents
        </div>
        <div class="appProgStep " style="z-index:3;" id="app3">Health-Based Questions</div>
        <div class="appProgStep " style="z-index:2;" id="app4">Payment Details</div>
        <div class="appProgStep active appProgStep-last" style="z-index:1;" id="app5">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <div class="row demomargin">
            <div class="col-lg-8 summary">
                <div class="row">
                    <div class="col-lg-6 col-xs-8">
                        <div class="head">Demographics</div>
                    </div>
                    <div class="col-lg-6 col-xs-4">
                        <div style="text-align: right;color: #9090f7;margin-top: 2%;cursor: pointer;"
                            (click)="changeToDemography()">Change</div>
                    </div>
                </div>
                <div class="card">
                    <div class="" *ngFor="let item of memberdata">
                        <div class="plan-card-header apclnt-card-header " style="margin-left: 0.05rem;">
                            <h6 class="mb-0 py-2 f-600">
                                <div class="header_t">
                                    {{item.applicanttype | titlecase }}: <span class="sub_txt">
                                        {{item.firstname | titlecase }}&nbsp;
                                        {{item.lastname | titlecase }}</span>
                                </div>
                            </h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-xs-12">
                                <div class="head_txt">Gender : <span class="cart_txt">{{item.gender}}</span></div>
                            </div>
                            <div class="col-lg-4  col-xs-13">
                                <div class="head_txt">SSN : <span class="cart_txt">{{item.ssn4}}</span></div>
                            </div>
                            <div class="col-lg-5">
                                <div class="head_txt"> DOB : <span class="cart_txt">{{item.dob |
                                        date:'MM/dd/yyyy'}}</span></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="head_txt">Phone : <span class="cart_txt">{{item.phone}}</span></div>
                            </div>
                            <!-- <div class="col-lg-4">
                                <div class="head_txt"><span>Email :</span> <span class="cart_txt">{{item.email}}</span></div>
                            </div> -->
                            <div class="col-lg-8">
                                <div class="head_txt">Height & Weight : <span class="cart_txt">{{item.height}}
                                        &nbsp;&nbsp; {{item.weight}}lbs</span></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="head_txt"><span>Email :</span> <span class="cart_txt">{{item.email}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="head_txt">Address :
                                    <span class="cart_txt">
                                        {{item.address1 | titlecase}}, <span *ngIf="item.address2">{{item.address2 |
                                            titlecase}}, </span>{{item.city | titlecase }}, {{item.state |
                                        titlecase }}
                                        {{item.zip | titlecase }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xs-8">
                        <div class="head" style="margin-top: 0%;">Payments Details</div>
                    </div>
                    <div class="col-lg-6 col-xs-4">
                        <div (click)="changeToPaymeny()"
                            style="text-align: right;color: #9090f7;margin-top: 2%;cursor: pointer;">Change</div>
                    </div>
                </div>
                <div class="card" *ngIf="cardType">
                    <div class="">
                        <div class="plan-card-header apclnt-card-header " style="margin-left: 0.05rem;">
                            <h6 class="mb-0 py-2 f-600">
                                <div class="header_t">
                                    BANK ACH
                                </div>
                            </h6>
                        </div>
                    </div>
                    <div *ngFor="let item of paymentData" style="display: flex; flex-direction: row;">
                        <div style="width:40%;">
                            <div class="head_txt">Routing Number : <span class="cart_txt">{{item.routing}}</span></div>
                            <div class="head_txt">Account Number : <span class="cart_txt">{{item.account}}</span></div>
                        </div>
                        <div style="width:60%;">
                            <div style="color: red;text-align: center;">
                                {{errMsg}}
                            </div>
                            <div style="width:60%;">
                                <div style="color: red;text-align: center;">
                                    {{errorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart_txt">{{account_type}}</div>
                </div>
                <div class="card" *ngIf="!cardType">
                    <div class="">
                        <div class="plan-card-header apclnt-card-header " style="margin-left: 0.05rem;">
                            <h6 class="mb-0 py-2 f-600">
                                <div class="header_t">
                                    Credit Card
                                </div>
                            </h6>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftsec">
                            <div class="head_txt">Card Number: <span class="cart_txt">{{cardData}}</span></div>
                        </div>
                        <div class="rightsec">
                            <div style="color: red;text-align: center;">
                                {{errorMessage}}
                            </div>
                            <div style="color: red;text-align: center;">
                                {{errMsg}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xs-8">
                        <div class="head statementHeader"> Statement of Standards</div>
                    </div>
                </div>
                <div class="card demography">
                    <ul class="statement_txt">
                        <li>I commit to maintain a moral and ethical lifestyle.</li>
                        <li>I commit to refrain from the use of tobacco and illegal drugs.</li>
                        <li>I commit to refrain from excessive alcohol consumption.</li>
                        <li>I commit to maintain a healthy diet and to exercise regularly.</li>
                        <li>I commit to sharing one another’s burdens.</li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <div class="head" style="margin-top: 0%;"> Electronic Signature</div>
                    </div>
                </div>
                <div class="card mobilecard">
                    <div class="">
                        <label>
                            <input type="checkbox" (click)="onCheckbox($event)"><small> By checking this box, you
                                acknowledge that
                                your electronic signature as
                                authorized
                                representative for this application will have the same legal effect as a handwritten
                                signature <span class="fieldMan">*</span>
                            </small>
                        </label>
                        <br>
                        <label>
                            <input type="checkbox"><small> I agree to the Fellowship HealthShare Basic Terms and
                                Conditions. <span class="fieldMan">*</span>
                            </small>
                        </label>
                        <div *ngIf="showCheckboxError !=''" class="error">
                            {{showCheckboxError}}
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-lg-6 col-xs-12">
                                By:<input placeholder="signature" (focusout)="onfocusoutSignature()" id="signature"
                                    class="input" type="text" style="font-size: 16px;   font-style: italic;" />
                                <!---font-family: 'Cedarville Cursive', cursive;-->
                                <div>
                                    <div style="font-size:10px;margin-left: 30px;">Signature of Primary Applicant <span
                                            class="fieldMan">*</span></div>
                                </div>
                                <div>Name : {{applicantname}}</div>
                            </div>
                            <div class="col-lg-6 col-xs-12">
                                Date:<input class="input" style="width: 146px; text-align: center;" placeholder="date"
                                    value="{{date | date:'MM/dd/yyyy'}}" />
                            </div>
                        </div>
                        <div *ngIf="showSignatureVerifyError !=''">
                            <p class="error">
                                {{showSignatureVerifyError}}
                            </p>
                            <p class="error">
                                {{showSignatureVerifyError1}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4" style="margin-top: 2.5%;padding-right: 0;">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6">
                            <div class="planehead">Zip Code</div>
                            <div class="planeh">Effective Start Date</div>
                        </div>
                        <div class="col-lg-6 col-xs-6" style="text-align: right;">
                            <div class="planehead"> {{applicant.zipcode}}</div>
                            <div class="planeh"> {{applicant.startdate | date:'MM/dd/yyyy'}}</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6">
                            <div>Application Cart</div>
                        </div>
                    </div>
                </div>
                <app-applicationcart></app-applicationcart>
                <div class="card" *ngFor="let item of agentInfo">
                    <div class="row">
                        <div class="col-lg-12 cartHead">
                            Agent Information
                        </div>
                        <div class="col-lg-12"> {{item.firstname}} {{item.lastname}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-4">
                            <div class="planehead">Mobile &nbsp; : </div>
                            <div class="planeh">E-mail &nbsp; :</div>
                        </div>
                        <div class="col-lg-8 col-xs-8">
                            <div class="planehead">{{item.mobile}}</div>
                            <div class="planeh">{{item.email}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row buttons">
        <div class="col-lg-6 col-xs-6">
            <button type="button" class="btn btn-primary" (click)="changeToPaymeny()">Previous</button>
        </div>
        <div class="col-lg-6 col-xs-6" style="text-align: right;">
            <button type="button" class="btn btn-success" (click)="finalSubmit()">Submit Application</button>
        </div>
    </div>