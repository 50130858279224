<div class="container m-top demograpy">
  <div class="applicationProgress">
    <div class="appProgStep active" style="z-index:6; padding-left: 30px;" id="app2"> Add-On/Supplemental Products
    </div>
    <div class="appProgStep " style="z-index:5; padding-left: 40px;" id="app1">Demographics &amp; Dependents
    </div>
    <div class="appProgStep " style="z-index:3;" id="app3">Health-Based Questions</div>
    <div class="appProgStep" style="z-index:2;" id="app4">Payment Details</div>
    <div class="appProgStep appProgStep-last" style="z-index:1;" id="app5">
      <span>Summary</span>
    </div>
  </div>
  <div class="m-top">
    <div class="row demomargin">
      <div class="col-md-4 padding_demo">
        <div class="card">
          <div class="row">
            <div class="col-lg-8">
              <div class="planeh">Effective Start Date</div>
            </div>
            <div class="col-lg-4" style="text-align: right;">
              <div class="planeh"> {{applicant?.startdate | date:'MM/dd/yyyy'}}</div>
            </div>
          </div>
        </div>
        <div class="card  ">
          <div class="row">
            <div class="col-lg-5">
              <div>Application Cart</div>
            </div>
            <div class="col-lg-7" style=" text-align: right;">
              <div class="returnPlane" (click)="backtoD2C()">
                <span style="color: white;">Return to Plan</span>
              </div>
            </div>
          </div>
        </div>
        <app-applicationcart></app-applicationcart>
        <div class="col-xs-12 mb-2">
          <p><span class="fieldMan">*</span>The one-time fee does not apply to the discount health benefits.</p>
        </div>
      </div>
      <div class="col-8 padding_demo1">
        <div class="row nxt">
          <div class="col-10"></div>
          <button type="button" class="col-md-2 float-right  btn btn-primary" (click)="goTODemography()">Next</button>
        </div>
        <div class="row m-0">
          <h3 class="hdr">Our recommended add-on and supplemental programs for your complete healthcare experience</h3>
        </div>
        <div *ngIf="products?.data.addon && add_on_products_choice?.length>0">
          <div class="header my-3" *ngIf="add_on_products_choice?.length>0">
            <h4>Add on Products for Choice Program</h4>
          </div>
        </div>
        <div class="panel-body" *ngIf="products?.data.addon && add_on_products_choice?.length>0">
          <div class="row " *ngFor="let product of add_on_products_choice">
            <div class="productCard col-12">
              <ng-template *ngTemplateOutlet="product_card; context: {$implicit: product}"></ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="products?.data.addon && add_on_products_basic?.length>0">
          <div class="header" *ngIf="add_on_products_basic?.length>0">
            <h4>Add on Products for Basic Program</h4>
          </div>
        </div>
        <div class="panel-body" *ngIf="products?.data.addon && add_on_products_basic?.length>0">
          <div class="row " *ngFor="let product of add_on_products_basic">
            <div class="productCard col-12">
              <ng-template *ngTemplateOutlet="product_card; context: {$implicit: product}"></ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="products?.data.addon && add_on_products_essential?.length>0">
          <div class="header my-3" *ngIf="add_on_products_essential?.length>0">
            <h4>Add on Products for Essential Program</h4>
          </div>
        </div>
        <div class="panel-body" *ngIf="products?.data.addon && add_on_products_essential?.length>0">
          <div class="row " *ngFor="let product of add_on_products_essential">
            <div class="productCard col-12">
              <ng-template *ngTemplateOutlet="product_card; context: {$implicit: product}"></ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="header" *ngIf="products?.data.supplement">
        <h4>Supplemental health benefits</h4>
      </div>
      <div class="panel-body" *ngIf="products?.data.supplement">
        <div class="row " *ngFor="let product of supplemetal_products">
          <div class="productCard col-12">
            <ng-template *ngTemplateOutlet="product_card; context: {$implicit: product}"></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #product_card let-product>
    <div class="product-logo">
      <img class="img-responsive serviceProviderLogo" src="{{imgPath}}{{product.product_image}}">
    </div>
    <div class="col-12 px-0">
      <h6 class="product-name">
        {{product.product_name}}
      </h6>
      <p class="mb-2">
        {{product.description}}
      </p>
      <div class="row info align-items-center justify-content-between">
        <div class="col-auto pl-0">
          <h6 class="pro-price mb-0 ">
            ${{product.retail_price}}
          </h6>
        </div>
        <div class="col-auto text-right d-flex">
          <div *ngIf="product.plan_id == 1 || product.plan_id == 2">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#detailsPopup"
              (click)="viewProductDetails(product)">Product Details</button>
          </div>
          <div *ngIf="product.plan_id == 3">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#hc2uDetailsPopup"
              (click)="viewProductDetails(product)">Product Details</button>
          </div>

          <button *ngIf="!checkStatus(product) && product.active" type="button" class="float-right  btn btn-success ml-4"
            (click)="addProduct(product)"><i>
              <img src="assets/images/shopping-cart1.png"></i>Add to cart
          </button>
          <div class="" *ngIf="checkStatus(product) && !product.active">
            <button *ngIf="checkStatus(product) && !product.active" type="button" class="float-right  btn btn-danger ml-1"
              (click)="removeProduct(product)"><i>
              </i>Remove
            </button>
          </div>

        </div>
      </div>
    </div>
  </ng-template>
  <mtm-details #mtmDetails></mtm-details>
  <hc2u-details #hc2uModal></hc2u-details>
