<div class="container  membership">
  <div class="row">
    <div class="col-sm-12 mb-3">
      <h2>membership Plans</h2>
      <p class="services text-center">
        Membership in Fellowship HealthShare means that you are joining a medical cost sharing community of like-minded
        people who are tired of rising healthcare costs and outrageous insurance premiums. We are a group of people who
        have decided to take control of our healthcare through cost-containment strategies. Once all cost-containment
        strategies are exhausted and you have reached your MRA (Member Responsibility Amount - which is similar to a
        deductible in the insurance world), the remaining medical needs will be shared amongst the members in our
        community to ensure that you are able to access quality care at the most affordable price!
      </p>
    </div>
  </div>
</div>
<div class="container">
  <div class=" rounded mt-n9">
    <div class="row justify-content-md-centered">
      <div class="col-md-6 col-lg-4 order-lg-2 mb-3 mb-md-0">
        <!-- Services -->
        <div class="bg-white shadow-sm rounded py-4">
          <!-- Header -->
          <header class="text-center px-4 mb-2">
            <img src="assets/img/planicon/basicd.jpg">
            <hr>
            <p class="font-size-13">CONTRIBUTIONS AS LOW AS</p>
            <h2 class="h5 text-green">$120/month</h2>
            <p>Great for two people that want the added security.</p>
            <hr>
            <div class="row">
              <div class="col-12 text-center py-2"><button type="button" routerLink="/dynamicplan"
                  class="btn btn-flat btn-sm btn-block btn-primary">Become A Member</button></div>
            </div>
          </header>
          <ul class="list-group list-group-rounded-0 list-group-borderless list-group-striped">
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Telemedicine Consults for $15 per Visit
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              2 Office Visits with No Network Restrictions per Member per year
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Access to the Point Health Mobile App
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Discount Prescriptions
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Discount Chiropractic
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Cancer Treatment sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Laboratory Services
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Chaplaincy & Counseling
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Discount Dental,Vision & Hearing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Concierge Style Healthcare Navigation
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Annual Sharing Limit of $250,000 and Lifetime Maximum Limit of $500,000
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Medical Bill Negotiation
            </li>
          </ul>
          <div class="row">
            <div class="col-12 text-center py-3">
              <a class="btn btn-secondary" type="button" target="_blank"
                href="assets/document/fellowship-basic-program-feb2022.pdf"><i class="fa fa-link"
                  aria-hidden="true"></i> Quick Reference Guide</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 order-lg-2 mb-3 mb-md-0">
        <div class="bg-primary shadow-sm rounded py-4">
          <header class="text-center px-4 mb-2">
            <img src="assets/img/planicon/essentialdove.jpg">
            <hr>
            <p class="font-size-13">CONTRIBUTIONS AS LOW AS</p>
            <h2 class="h5 text-green">$220/month</h2>
            <p>Lower contributions for a lower Lifetime Limit.</p>
            <hr>
            <div class="row">
              <div class="col-12 text-center py-2"><button type="button" routerLink="/dynamicplan"
                  class="btn btn-flat btn-sm btn-block btn-primary">Become A Member</button></div>
            </div>
          </header>
          <ul class="list-group list-group-rounded-0 list-group-borderless   list-group-striped">
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Direct Primary Care & Urgent care Visits
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              4 Out of Network Office visits per Member per Year
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Telemedicine
              <p>&nbsp;</p>
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Maternity sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Adoption sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Cancer Treatment sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Laboratory Services
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Chaplaincy & Counseling
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Discount Dental,Vision & Hearing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Concierge Style Healthcare Navigation
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Annual Sharing Limit of $500,000 and Lifetime Maximum Limit of $1,000,000
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Medical Bill Negotiation
            </li>
          </ul>
          <div class="row">
            <div class="col-12 text-center py-3">
              <a class="btn btn-secondary" type="button" target="_blank"
                href="assets/document/fellowship-essential-program-feb2022.pdf"><i class="fa fa-link"
                  aria-hidden="true"></i> Quick Reference Guide</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 order-lg-2 mb-3 mb-md-0">
        <div class="bg-white shadow-sm rounded py-4">
          <header class="text-center px-4 mb-2">
            <img src="assets/img/planicon/choice1.jpg">
            <hr>
            <p class="font-size-13">CONTRIBUTIONS AS LOW AS</p>
            <h2 class="h5 text-green">$250/month</h2>
            <p>Perfect for growing families that want a low MRA</p>
            <hr>
            <div class="row">
              <div class="col-12 text-center py-2"><button type="button" routerLink="/dynamicplan"
                  class="btn btn-flat btn-sm btn-block btn-primary">Become A Member</button></div>
            </div>
          </header>
          <ul class="list-group list-group-rounded-0 list-group-borderless list-group-striped">
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Direct Primary Care & Urgent care Visits
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              6 Out of Network Office visits per Member per Year
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Unlimited Telemedicine
              <p>&nbsp;</p>
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Maternity sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Adoption sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Cancer Treatment sharing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Laboratory Services
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Chaplaincy & Counseling
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Discount Dental,Vision & Hearing
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Concierge Style Healthcare Navigation
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Annual Sharing Limit of $1,000,000 and Lifetime Maximum Limit of $2,000,000
            </li>
            <li class="list-group-item d-flex align-items-center py-3">
              <i class="fa fa-check text-primary mr-3"></i>
              Medical Bill Negotiation
            </li>
          </ul>
          <div class="row">
            <div class="col-12 text-center py-3">
              <a class="btn btn-secondary" type="button" target="_blank"
                href="assets/document/fellowship-choice-program-feb2022.pdf"><i class="fa fa-link"
                  aria-hidden="true"></i> Quick Reference Guide</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-50 w-lg-35 mx-auto space-2">
    </div>
  </div>
</div>
<div class="divider">
</div>
<div class="body_header py-3">Services</div>
<div class="container mt-3 mb-4">
  <p class="services1">
    Our cost-containment strategy includes products that prevent members from incurring
    excessive cost and healthcare services that are not covered under the Fellowship membership sharing.
  </p>
</div>
<div class="bg-graylight">
  <div class="membership container">
    <div class="col-sm-12 service-text">
      <div class="pd-20">
        <div class="row bg-white ">
          <div class="col-sm-3 flex  service-box-shadow">
            <div class="center">
              <img class=" img-border max-width-9" src="assets/img/vision2.png" alt="Image Description">
              <h4 class="h6 text-dark">Vision</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              This vision program offers members 10% to 60% off eyeglasses, contact lenses and other retail eyewear
              items as well as 10% to 30% off eye examinations and 40% to 50% off the national average on LASIK. Over
              20,000 eye care professionals nationwide – including many local opticians – participate in this Benefit.
            </p>
            <p>
              Some Major Participating Eye Care Professionals Include:
            </p>
            <ul>
              <li>
                For Eyes Optical</li>
              <li>
                JCPenney</li>
              <li> LensCrafters</li>
              <li> Pearle Vision</li>
              <li> Sterling Optical</li>
              <li> Target Optical</li>
            </ul>
            <p>
              May not be used in conjunction with other discounts or insurance.
            </p>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9" src="assets/img/dentistry.png" alt="Image Description">
              <h4 class="h6 text-dark">Dental</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Members can save 15% to 50%* per visit, in most instances, on services at any of the many available dental
              practice locations nationwide.
            </p>
            <p>
              Dental services include:
            </p>
            <ul>
              <li>
                Cleanings</li>
              <li>
                X-rays</li>
              <li> Fillings</li>
              <li> Root Canals</li>
              <li> Crowns</li>
            </ul>
            <p>
              The discount program provides access to the Aetna Dental Access® network. This network is administered by
              Aetna Life Insurance Company (ALIC). Neither ALIC nor any of its affiliates offers or administers the
              discount program. Neither ALIC nor any of its affiliates is an affiliate, agent, representative or
              employee of the discount program. Dental providers are independent contractors and not employees or agents
              of ALIC or its affiliates. ALIC does not provide dental care or treatment and is not responsible for
              outcomes.
            </p>
            <p>* Actual costs and savings vary by provider, service and geographical area.</p>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/img/chripotic1.png" alt="Image Description">
              <h4 class="h6 text-dark mb-1">Chiropractic</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Millions of Americans rely on chiropractic care to reduce pain and stress and to function more
              productively. Members can save 20% to 40% on chiropractic fees at participating chiropractors across the
              country.</p>
            <p> While our provider lists are continually updated, provider status can change. We recommend that you
              confirm the provider you selected participates in the program when scheduling your appointment.</p>
            <p>Cannot be used in conjunction with any other discount program.</p>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9  br-50" src="assets/img/labs2.jpg" alt="Image Description">
              <h4 class="h6 text-dark mb-1">Labs</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Serious medical conditions can go undetected for years without noticeable symptoms. The earlier a problem
              is detected, the easier and more likely it is to be treatable. DirectLabs® is the leader in direct access
              laboratory testing and provides access to major clinical labs nationwide. Confidential results are
              available online in as little as 24 hours for most tests.</p>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/img/imaging-benifits.png" alt="Image Description">
              <h4 class="h6 text-dark mb-1">Imaging Benefits</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <h5>Imaging</h5>
            <p>Members can save on imaging services such as MRIs, CT Scans, and XRays. A Service Representative will
              assist Members in finding the lowest
              price on procedures at a participating network provider and confirm the
              Member’s out of pocket responsibility before appointment scheduling. Our
              network providers can save members an average of 40% - 75% less than the
              usual costs for advanced radiology testing.
              <!-- <a   target="_blank" href="assets/document/Imaging.pdf">Read More</a> -->
            </p>
            <p>This Program cannot be used in conjunction with insurance. Providers may
              not be available in all areas. Savings may vary based on geographic
              location, provider and procedure performed. Available services may vary by
              provider.</p>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white ">
          <div class="col-sm-3  service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/img/home-supplies.jpg" alt="Image Description">
              <h4 class="h6 text-dark mb-1">Diabetic & Home Supplies</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Members enjoy fast, easy access to reliable and affordable diabetic, health and wellness, and pet health
              supplies. Save an additional 10% on products by ordering online or by phone.<br>
              Members can save on many items:</p>
            <ul>
              <li> Diabetic Supplies (test strips, insulin pump supplies, lancets and more)</li>
              <li> Home Medical Supplies (bath seats, walkers, and more)</li>
              <li>Pet Health Supplies (pet diabetic supplies, pet care, and pet food)</li>
              <li> Health and Wellness Supplies (blood pressure monitors, incontinence products, baby formula and much
                more)</li>
            </ul>
            <h5>THIS IS NOT INSURANCE</h5>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white ">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9  br-50" src="assets/img/icon-telehealth_0.png"
                alt="Image Description">
              <h4 class="h6 text-dark mb-1">Telehealth</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Telehealth saves time and money. We provide you unparalleled services that will help you avoid busy
              waiting rooms and save your heard-earned dollars on office visits, urgent care and the ER. Fellowship
              Members have 24/7/365 access to quality physicians through their phone.</p>
            <p> Virtual consultations enable physicians to diagnose numerous common conditions and recommend treatment
              plans and/or write prescriptions that they may send to the pharmacy of your choosing.
            </p>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/img/rx1.png" alt="Image Description">
              <h4 class="h6 text-dark mb-1">Rx Discounts</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              These days, the cost of prescription drugs can be staggering. Rx Discounts provides a much-needed relief
              from the pain of spending hundreds of dollars on these medications by bringing steep discounts to you.
              <br>
              Fellowship members benefit from these great discounts on the medicines that keep them happy and healthy!
            </p>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/images/healthnav.png" alt="Image Description">
              <h4 class="h6 text-dark mb-1 pt-1">Healthcare Navigation</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Our concierge-style healthcare navigation services guide members through the messy and expensive
              healthcare system while ensuring access to quality and
              affordable care at top facilities. To access this service, members simply open their mobile app, search
              for the medical services they need, and select the option that makes the most sense for them! Members can
              choose facilities based on how close they are to them, their ratings, and affordability. If a member
              prefers some extra help, they can call Member Services to receive assistance from a dedicated patient
              advocate.
            </p>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9 " src="assets/images/billneg.png" alt="Image Description">
              <h4 class="h6 text-dark mb-1 pt-1">Medical Bill Negotiation</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              Major healthcare events are already stressful. Expensive and confusing medical bills just add to that
              stress. The Fellowship HealthShare experts are here to take care of our members and negotiate their
              medical expenses on their behalf. Not only do patient advocates reduce your stress, but they also have an
              average bill reduction of 44%! Cash-pay patients often have access to several discounts for health
              expenses, but very few patients know about these discounts. Our highly trained Patient
              Advocates understand the various options in every medical situation and are available to our members to
              bring their medical costs down significantly. If a member experiences a major health event, simply call
              Member Services and our team will walk hand in hand with the member or their representative to take the
              next steps towards a solution.
            </p>
          </div>
        </div>
      </div>
      <div class="pd-20">
        <div class="row bg-white">
          <div class="col-sm-3 flex service-box-shadow">
            <div class="center">
              <img class="img-border max-width-9" src="assets/img/comunity1.png" alt="Image Description">
              <h4 class="h6 text-dark">The power of community</h4>
            </div>
          </div>
          <div class="col-sm-9 mt-auto">
            <p>
              The Fellowship HealthShare community shares what they have to bless those in need. Our members feel
              confident that they are sharing healthcare costs with a community that shares in their beliefs and ethics.
              Through the power of community, we are committed to providing best in class service to our members through
              charitable donations and medical expense sharing.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
