<section>
    <div class="container">
       
        <div class="row">
            <div class="col-md-12 col-sm-12  col-lg-6">
                <h2>What we do</h2>
                <p> Our Members Shared Beliefs <br> As a Fellowship HealthShare member, you’ll feel confident that
                    you’re
                    sharing healthcare costs with a community that shares in your faith: <br> 1. We believe it is our
                    right to
                    make our own decisions regarding healthcare, in consultation with physicians, family and/or other
                    valued
                    advisors, free from any oversight and restraints. <br> 2. We believe our actions must be ethical and
                    moral.
                    <br> 3. We believe it is our spiritual duty to God and our ethical duty to others to maintain a
                    healthy
                    lifestyle and avoid behaviors or habits that produce sickness or disease to ourselves or others.
                    <br> 4. We
                    believe it is our ethical obligation to assist others with medical needs according to our available
                    resources
                    and opportunity. <br>
                </p>
            </div>
            <div class="col-md-12  col-sm-12 col-lg-6" style="text-align: right;">
                <img src="assets/img/whatwedo.jpg">
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">

        <div class="row">
            <div class="col-md-12  col-sm-12 col-lg-6" style="text-align: left;">
                <img  src="assets/img/whatweoffer.jpg">
            </div>
            <div class="col-md-12 col-sm-12  col-lg-6">

        <h2>What we offer </h2>
                <p > We have been sharing medical expenses for decades. As a non profit
                    organization, we
                    are committed to providing best in class service to our members through charitable donations and
                    medical
                    expense sharing. The services we offer are not insurance but we believe that the members are better
                    served
                    through the financial stewardship that comes with a health sharing membership. Do you want to know
                    how you can
                    take control of your healthcare? </p>
            </div>
        </div>

    </div>
</section>
<section>
    <div class="container">

        <div class="row">
            <div class="col-md-12  col-sm-12 col-lg-6" style="text-align: left;">

        <h2>Affiliate Producer Program</h2>
                <p > The Affiliate Producer Program provides the individual with financial reward
                    when you
                    bless your friends, family or fellow church members by helping them to enroll in Fellowship
                    Healthshare. By
                    bringing new members into the Fellowship community, you can earn. It’s a Win-Win! Apply today to
                    learn how you
                    can be blessed by blessing others. </p>
            </div>
            <div class="col-md-12 col-sm-12  col-lg-6" style="text-align: right;">
                <img  src="assets/img/affiliateproducer1.jpg">
            </div>
        </div>

    </div>
</section>

<section>
    <div class="container">


     
        <div class="row">
            <div class="col-md-12  col-sm-12 col-lg-4" style="text-align: left;">
                <img  src="assets/img/memberservice.jpg">
            </div>
            <div class="col-md-12 col-sm-12  col-lg-8">
                <h2> Member Services</h2>
                <p >As a member of Fellowship HealthShare, you will have 24/7/365 access to many of
                    our
                    services such as Telemedicine, Direct Primary Care, Urgent Care, and Mental Health! For questions
                    regarding
                    your Membership, feel free to call Fellowship HealthShare during normal business hours (Monday
                    through Friday
                    from 8am to 5pm Central Standard Time), a Member Services Representative will be happy to assist
                    you. For
                    Fellowship Choice and Essential Members who wish to schedule a consultation with a Mental Health
                    Therapist or
                    a healthcare provider through telemedicine, Direct Primary Care, or Urgent Care; call the
                    Healthcare2U Central
                    Scheduling Department at (800) 496-2805. For Fellowship Basic Members who wish to access a
                    Telemedicine
                    physician or a Mental Health Therapist, download the free MyTelemedicine mobile app on your iPhone
                    or Android
                    and sign in to your free membership to schedule a consultation.
                </p>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">

        
        <div class="row">
            <div class="col-md-12  col-sm-12 col-lg-6">
                <h2>   Why us? </h2>
                <p > Because we are real Christians looking out for those who need access to top
                    quality
                    health benefits at an affordable price. Whether you are looking for group plans, individual plans,
                    or family
                    plans, we have you covered and we promise to pray for your good health! We aim to do healthcare
                    sharing the
                    right way. </p>
            </div>
            <div class="col-md-12 col-sm-12  col-lg-6" style="text-align: right;">
                <img  src="assets/img/whyus3.png">
            </div>
        </div>
    </div>

</section>