<section>
  <div class="container">
    <h2>Forms And Documents</h2>
    <div class="card">
      <div class="card-body" id="heading7Two">
        <div class="row">
          <div class="col-md-8">
            <h5 class="mb-0">
              Fellowship Brochure </h5>
          </div>
          <div class="col-md-4 d-flex justify-content-between">
            <a class="btn btn-secondary" download href="assets/document/fellowship-brochure-feb2022.pdf"><i
                class="fa fa-download" aria-hidden="true"></i> Download</a>
          
            <a class="btn btn-secondary " target="_blank" href="assets/document/fellowship-brochure-feb2022.pdf"><i
                class="fas fa-file-pdf"></i> View Brochure</a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body" id="heading7Two">
        <div class="row">
          <div class="col-md-8">
            <h5 class="mb-0">
              Reimbursement Form </h5>
          </div>
          <div class="col-md-4 d-flex justify-content-between">
            <a class="btn btn-secondary" download href="assets/document/Reimbursement_form.pdf"><i
                class="fa fa-download" aria-hidden="true"></i> Download</a>
         
            <a class="btn btn-secondary " target="_blank" href="assets/document/Reimbursement_form.pdf"><i
                class="fas fa-file-pdf"></i> View Form</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
