import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SharingService } from '../services/sharing.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-employer-group',
  templateUrl: './employer-group.component.html',
  styleUrls: ['./employer-group.component.scss']
})
export class EmployerGroupComponent implements OnInit {
  newForm: FormGroup;
  submitAttempt: boolean = false;
  loader: boolean = false;
  companyId: any;
  CompanyName: any;
  companyInfo: any;
  constructor(
    private objectService: TestService,
    private formBuilder: FormBuilder,
    private router: Router,
    private service: SharingService,
    private toastr: ToastrService
  ) {
    this.service.clientData.subscribe((data) => {
      if (data != '') {
      this.companyInfo = data;
      this.companyId =   this.companyInfo.company_id,
      this.CompanyName=  this.companyInfo.company_name
      }
    })
  }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
    this.newForm = this.formBuilder.group({
      orgname: ['', Validators.required],
      pContactName: ['', Validators.required],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
    })
  }
  submit(value) {
    this.submitAttempt = true
    if (this.newForm.valid) {
      let body = {
        organization_name: value.orgname,
        primary_contact_name: value.pContactName,
        address: value.address,
        phone: value.phone.replace(/[^0-9\.]+/g, ""),
        email: value.email,
        company_id: this.companyId,
        company_name: this.CompanyName
      }
       this.loader = true;
      var url = 'CreateSalesLead'
      this.service.postData(url, body).subscribe((Object: any) => {
        if (Object != null) {
          this.toastr.success('Thanks for mailing us! We will be in touch with you ');
          this.newForm.reset();
          this.submitAttempt = false;
           this.loader = false;
        }
      }, err => {
        console.log(err);
        this.loader = false;
        this.toastr.error('Something went wrong. Please try again later.');
      })
    }
  }

  onfocusoutphnumber(feature) {
    if (feature == "primary") {
      let text = (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML;
      let value = (<HTMLInputElement>document.getElementById("phnumber")).value;
      if (value.length < 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "Please enter valid Phone number";
      }
      if (value.length >= 12) {
        (<HTMLInputElement>document.getElementById("phnumbererrmsg")).innerHTML = "";
      }
    }
  }
  onkeyupPhone(e) {
    let phnumbervalue = (<HTMLInputElement>document.getElementById("phnumber")).value;
    let transformedInput = phnumbervalue.replace(/^0*/g, '');
  }
  //================number validation==============//
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
