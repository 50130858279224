import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';


@Component({
  selector: 'app-blogdetail',
  templateUrl: './blogdetail.component.html',
  styleUrls: ['./blogdetail.component.scss']
})
export class BlogdetailComponent implements OnInit {
  blogDetails: any;
  constructor(private objectService: TestService, private sharingService: SharingService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
    this.blogDetails = this.sharingService.getBlogdetails();
    if (this.blogDetails) {
      setTimeout(() => {
        var images = document.getElementById('dynamicblogdata').getElementsByTagName('img');
        for (var i = 0; i < images.length; i++) {
          images[i].className += "dynamicblogimage";
        }
      }, 100);
    }
  }

}
