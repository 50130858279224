import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  subject = new Subject();

  private memberinfo = new BehaviorSubject<any>('');//step1
  private memberinfostep2 = new BehaviorSubject<any>('');//step1
  private memberbackinfo = new BehaviorSubject<any>('');//step1

  private appCartMemberInfo = new BehaviorSubject<any>('');
  private MemberData = new BehaviorSubject<any>('');
  private usernameSource = new BehaviorSubject<any>('');
  private AddCartPlan = new BehaviorSubject<any>('');
  private demographyObj = new BehaviorSubject<any>('');
  private paymentDetails = new BehaviorSubject<any>('');
  private searchDetails = new BehaviorSubject<any>('');
  private backQuestions = new BehaviorSubject<any>('');
  private backtoPlans = new BehaviorSubject<any>('');
  private backtoSummary = new BehaviorSubject<any>('');
  private demotoA2c = new BehaviorSubject<any>('');
  //new 
  private appCartData = new BehaviorSubject<any>('');

  memberInfoData = this.memberinfo.asObservable();//step1
  memberInfostep2 = this.memberinfostep2.asObservable();//step1
  memberBackInfo = this.memberbackinfo.asObservable();//step1

  memberInfo = this.MemberData.asObservable();
  username = this.usernameSource.asObservable();
  cartPlan = this.AddCartPlan.asObservable();
  demographyObject = this.demographyObj.asObservable();
  paymentObject = this.paymentDetails.asObservable();
  searchObject = this.searchDetails.asObservable();
  questionsObject = this.backQuestions.asObservable();
  plansObject = this.backtoPlans.asObservable();
  plansSObject = this.backtoSummary.asObservable();
  demotoA2C = this.demotoA2c.asObservable();
  appcartData = this.appCartData.asObservable();
  cartMemberId = this.appCartMemberInfo.asObservable();
  cardName = "";
  cardcheckCondition: boolean;

  constructor() { }
  appCartmemberInfoInfo(company_id: any, member_id:any, cart_id:any) {
    this.appCartMemberInfo.next({company_id,member_id,cart_id} )
  }
  memberInFo(memberInfoData: any) {//step1
    this.memberinfo.next(memberInfoData);
  }
  memberFullDetails(memberInfostep2: any) {//step2
    this.memberinfostep2.next(memberInfostep2);
  }
  backMemberInFo(memberbackinfo: any) {
    this.memberbackinfo.next(memberbackinfo)
  }
  appCartInfo(appcartData: any) {
    this.appCartData.next("");
    this.appCartData.next(appcartData);
  }
  demoToA2c(demotoA2C: any) {
    this.demotoA2c.next(demotoA2C)
  }
  backToHome(memberInfo) {
    this.MemberData.next(memberInfo);
  }

  changeUsername(username: any) {
    this.usernameSource.next(username);
  }
  addCartPlan(cartPlan: any) {
    this.AddCartPlan.next(cartPlan);
  }
  demographyH(demographyObject: any) {
    this.demographyObj.next(demographyObject);
  }
  searchFilter(searchObject: any) {
    this.searchDetails.next(searchObject);
  }
  paymentMethod(paymentObject: any) {
    this.paymentDetails.next(paymentObject);
  }
  questionsback(questionsObject: any) {
    this.backQuestions.next(questionsObject);
  }
  plansback(plansObject: any) {
    this.backtoPlans.next(plansObject);
  }
  planSsback(plansSObject: any) {
    this.backtoSummary.next(plansSObject);
  }
  pushcardType(cardType) {
    this.cardName = cardType;
  }
  getCardType() {
    return this.cardName;
  }
  pushcardCheckCondition(condition) {
    this.cardcheckCondition = condition;
  }
  getcardCheckCondition() {
    return this.cardcheckCondition
  }


  pushheaderCondition(data) {
    this.subject.next(data);
  }
  getheaderCondition(): Observable<any> {
    return this.subject.asObservable();
  }
}
