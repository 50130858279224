<div class="card  ">
  <div *ngFor="let item of cartProducts">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-xs-8 cartHead">
        <div>
          {{item.name}} Program
        </div>
        <div *ngFor="let member of item.family_info" class="applicantName">
          {{member.firstname | titlecase}}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-4 carthead">
        {{item.total_price | currency:'USD'}}
      </div>
    </div>
  </div>
  <ng-container *ngIf="supplementalProducts.length>0">
    <div style="font-weight: 600;">Supplemental Products</div>
    <div class="row" *ngFor="let item of supplementalProducts">
      <div class="col-lg-8 col-md-8 col-xs-8 lfthead">
        {{item.Name}}
      </div>

      <div class="col-lg-4 col-md-4 col-xs-4 lfthead" style="text-align: right;">
        {{ item.retail_price | currency:'USD'}}
      </div>
    </div>
  </ng-container>


  <div class="row" *ngIf="addOnProducts.length>0">
    <div class="col-lg-12">
      <hr style=" border-top: 1px dashed  #c1c1c1;">
    </div>
  </div>
  <ng-container *ngIf="addOnProducts.length>0">
    <div style="font-weight: 600;">Add-on Products</div>
    <div class="row" *ngFor="let item of addOnProducts">
      <div class="col-lg-8 col-md-8 col-xs-8 lfthead">
        {{item.Name}}
      </div>

      <div class="col-lg-4 col-md-4 col-xs-4 lfthead" style="text-align: right;">
        {{item.retail_price | currency:'USD'}}
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-lg-12">
      <hr style=" border-top: 1px dashed  #c1c1c1;">
    </div>
    <div class="col-lg-6 col-md-8" style="font-weight: 600;">
      Total Price
    </div>
    <div class="col-lg-6 col-md-4" style="text-align: right;font-weight: 600;">
      {{totalAddOnsPrice | currency:'USD'}}
    </div>
  </div>
</div>
<div class="card  ">
  <div style="font-weight: 600;">Onetime Fee<span class="fieldMan">*</span></div>
  <div class="row" *ngFor="let item of cartFee">
    <div class="col-lg-6 col-md-6 col-xs-6 lfthead">
      {{item.fee_name}}
    </div>
    <div class="col-lg-6 col-md-6 col-xs-6 lfthead" style="text-align: right;">
      {{item.fee | currency:'USD'}}
    </div>
  </div>
  <div *ngIf="cartFee?.length > 0">
    <hr style=" border-top: 1px dashed  #c1c1c1;">
    <div class="row" *ngFor="let item of cartFee">
      <div class="col-lg-8 col-md-8 col-xs-8 lfthead" *ngIf="item.discount !=0">
        {{item.fee_name}} <span>Discount</span>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-4 lfthead" *ngIf="item.discount !=0" style="text-align: right;">
        {{item.discount | currency:'USD'}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <hr style=" border-top: 1px dashed  #c1c1c1;">
    </div>
    <div class="col-lg-6 col-md-6 col-xs-6" style="font-weight: 600;">
      Total Onetime Fee
    </div>
    <div class="col-lg-6 col-md-6 col-xs-6" style="text-align: right;font-weight: 600;">
      {{oneTimeFee | currency:'USD'}}
    </div>
  </div>
</div>

<div class="card">
  <div *ngIf="cartData?.length > 0">
    <div *ngFor="let data of cartData">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-xs-8" style="font-weight: 600;">
          Total Payments Today
        </div>
        <div class="col-lg-4 col-md-4 col-xs-4" style="text-align: right;font-weight: 600;">
          {{data.total | currency:'USD'}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-xs-8" *ngIf="monthlyContributionStartDate"
        style="font-weight: 600;text-align: initial;">
        Monthly Contribution Starting {{monthlyContributionStartDate|date:'MM/dd'}}
      </div>
      <div class="col-lg-4 col-md-4 col-xs-4" *ngIf="monthlyContribution" style="text-align: right;font-weight: 600;">
        {{monthlyContribution | currency:'USD'}}
      </div>
    </div>
  </div>
</div>
<!-- <div class="card" *ngIf="groupInfo?.length > 0">
  <div *ngFor="let item of groupInfo">
    <div class="row">
      <div class="col-lg-12 cartHead">
        Group Information
      </div>
      <div class="col-lg-12">{{item.name}}</div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-4" style="padding-right: 0;">
        <div class="planehead">Contact Name</div>
        <div class="planehead">Mobile &nbsp; </div>
        <div class="planeh">E-mail &nbsp; </div>
      </div>
      <div class="col-lg-8 col-xs-8">
        <div class="planehead">{{item.firstname}} {{item.lastname}}</div>
        <div class="planehead">{{item.mobile}}</div>
        <div class="planeh">{{item.email}}</div>
      </div>
    </div>
  </div>
</div> -->

<div class="card" *ngIf="groupInfo?.length > 0">
  <div *ngFor="let item of groupInfo">
    <div class="row">
      <div *ngIf="item.groupID != null" class="col-lg-12 cartHead">
        Group Information
      </div>
      <div *ngIf="item.agent_id != null" class="col-lg-12 cartHead">
        {{item.type}} Information
      </div>
      <div class="col-lg-12">{{item.name}}</div>
    </div>
    <div class="r">
      <table class="table borderless">
        <tr>
          <td style="width: 25%;">Name</td>
          <td style="width: 75%;">: {{item.firstname}} {{item.lastname}}</td>
        </tr>
        <tr>
          <td>Mobile</td>
          <td>: {{item.mobile}}</td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td style="word-break: break-all;">: {{item.email}}</td>
        </tr>
      </table>
      <!-- <div class="col-lg-4 col-xs-4" style="padding-right: 0;">
                <div class="planehead">Contact Name</div>
                <div class="planehead">Mobile &nbsp; </div>
                <div class="planeh">E-mail &nbsp; </div>
            </div>
            <div class="col-lg-8 col-xs-8">
                <div class="planehead">{{item.firstname}} {{item.lastname}}</div>
                <div class="planehead">{{item.mobile}}</div>
                <div class="planeh">{{item.email}}</div>
            </div> -->
    </div>
  </div>
</div>
