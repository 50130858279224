import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class AddOnService {

    private subject = new Subject<any>();

    sendClickEvent(e) {
        this.subject.next(e);
    }
    getClickEvent(): Observable<any> {
        return this.subject.asObservable();
    }
}