<div class="container">

    <h1>Terms of Use</h1>
    <p>

        Subject to your compliance with the Terms and Conditions, your acknowledgement of the Privacy Policy on The
        Company Site and your payment of any applicable fees, The Company grants you a limited, revocable,
        non-exclusive, non-transferable and non-sub-licensable license to access and make personal use of the Site and
        The Company Services. With the exception of your Electronic Medical Records, The Company retains all right,
        title and interest in, and to The Company, the Services and any Information, products, documentation, software
        or other materials on the Site. You agree not to reproduce, retransmit, distribute, disseminate, sell, publish,
        broadcast, or circulate such information to anyone. Use, reproduction, copying, or redistribution of The
        Company's trademarks, service marks and logos is strictly prohibited without the prior written permission of The
        Company.
        <br><br>
        You agree not to access the Site or use the Services in an unlawful way or for any unlawful purpose, including
        but not limited to: posting or transmitting a message under a false name, posting any data, materials, content
        or information which is libelous, defamatory, obscene, fraudulent, false or contrary to the ownership of
        intellectual property rights of any other person, or posting any content which contains or promotes any virus,
        worm, Trojan horse, time bomb or other computer programming or code that is designed or intended to damage,
        destroy, interfere or manipulate in any way, or otherwise interrupt or expropriate the Site or the Services,
        personal information, software, equipment, servers or Information or facilitate or promote hacking or similar
        conduct. The Company may delete any Information provided by you that it deems in its sole discretion fraudulent,
        abusive, defamatory, and obscene or in violation of a copyright, trademark or other intellectual property or
        ownership right of any other person.


    </p>


</div>
<br>
<br>