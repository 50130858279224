import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';

@Component({
  selector: 'app-adv-opinion-eligibility',
  templateUrl: './adv-opinion-eligibility.component.html',
  styleUrls: ['./adv-opinion-eligibility.component.scss']
})
export class AdvOpinionEligibilityComponent implements OnInit {

  constructor(private objectService: TestService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
