import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mtm-details',
  templateUrl: './mtm-details.component.html',
  styleUrls: ['./mtm-details.component.scss']
})
export class MtmDetailsComponent implements OnInit {
  showModal: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  show(){
    this.showModal = true;
  }

}
