<div class="container m-top demograpy">
    <div class="applicationProgress">
        <div class="appProgStep " style="z-index:6;" id="app2">Add-On/Supplemental Products
        </div>
        <div class="appProgStep active" style="z-index:5; padding-left: 30px;" id="app1">Demographics &amp; Dependents
        </div>
        <div class="appProgStep " style="z-index:3;" id="app3">Health-Based Questions</div>
        <div class="appProgStep" *ngIf="!groupID" style="z-index:2;" id="app4">Payment Details</div>
        <div class="appProgStep appProgStep-last" style="z-index:1;" id="app5">
            <span>Summary</span>
        </div>
    </div>
    <div class="container m-top">
        <div class="row demomargin">
            <div class="col-md-12  col-lg-4 padding_demo">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6">
                            <div class="planeh">Effective Start Date</div>
                        </div>
                        <div class="col-lg-6 col-xs-6" style="text-align: right;">
                            <div class="planeh"> {{startdate | date:'MM/dd/yyyy'}}</div>
                        </div>
                    </div>
                </div>
                <div class="card  ">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-5">
                            <div>Application Cart</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-6" style=" text-align: right;">
                            <div class="returnPlane" (click)="backtoD2C()">
                                <span style="color: white;">Return to Plan</span>
                            </div>
                        </div>
                    </div>
                </div>

                <app-applicationcart></app-applicationcart>
                
            </div>
            <div class="col-md-12 col-lg-8 padding_demo1">
                <form [formGroup]="auxConfigform">
                    <div class="card">
                        <!============******Primary Form*****===============>
                            <div *ngFor="let primary of demgPrimaryArray;let i =index" formArrayName="primaryArray">
                                <div [formGroupName]="i">
                                    <div *ngIf="disableGFields" class="plan-card-header apclnt-card-header "
                                        style="margin-left: 0.05rem;">
                                        Primary
                                    </div>
                                    <div *ngIf="!disableGFields" class="plan-card-header apclnt-card-header "
                                        style="margin-left: 0.05rem;">
                                        Guardian
                                    </div>
                                    <div class="row demomargin">
                                        <div class="col-lg-6">
                                            <div class="head_txt">First Name <span class="fieldMan">*</span></div>
                                            <input type="text" maxlength="25" placeholder="" formControlName="firstname"
                                                class="form-control">
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.firstname.valid && (primary.controls.firstname.touched || submitAttempt)">
                                                First name is required
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="head_txt">Last Name <span class="fieldMan">*</span></div>
                                            <input type="text" maxlength="25" placeholder="" formControlName="lastname"
                                                class="form-control">
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.lastname.valid && (primary.controls.lastname.touched || submitAttempt)">
                                                Last name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row demomargin">
                                        <div class="col-lg-3">
                                            <div class="head_txt">Phone Number <span class="fieldMan">*</span></div>
                                            <input type="text" disabled placeholder="" maxlength="12" id="phnumber"
                                                (focusout)="onfocusoutphnumber('primary')"
                                                (keyup)="onkeyupPhone($event)" (keypress)="numberOnly($event)"
                                                formControlName="phone" class="form-control" appPhonenumberValidation>
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.phone.valid && (primary.controls.phone.touched || submitAttempt)">
                                                Phone Number is required
                                            </div>
                                            <div class="errormsg" id="phnumbererrmsg">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="head_txt">Email<span class="fieldMan">*</span></div>
                                            <input type="text" disabled formControlName="email" class="form-control">
                                            <div class="errormsg"
                                                *ngIf=" (primary.controls.email.dirty && primary.controls.email.touched ) && primary.controls.email.hasError('pattern')">
                                                enter a valid Email Address
                                            </div>
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.email.valid && (primary.controls.email.touched || submitAttempt)">
                                                Email Address is required
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="head_txt">Social Security </div>
                                            <input autocomplete="off" class="form-control" type="text" placeholder=""
                                                maxlength="11" socialSecurity formControlName="ssn"
                                                (focusout)="onprimaryssnFocusout($event)"
                                                (focus)="onFocusPrimary($event)" (keyup)="onTypingSocialSecurity()"
                                                id="social-security" (keypress)="numberOnly($event)">
                                            <div class="errormsg" *ngIf="number!=''">
                                                {{number}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row demomargin">
                                        <div class="col-lg-3 col-xs-4" *ngIf="disableGFields">
                                            <div class="head_txt"> Height <span class="fieldMan">*</span></div>
                                            <input class="form-control" type="text" disabled placeholder="height"
                                                formControlName="height">
                                        </div>
                                        <div class="col-lg-2 col-xs-4" style="padding-left: 0;" *ngIf="disableGFields">
                                            <div class="head_txt">Weight <span class="fieldMan">*</span></div>
                                            <div class="input-group">
                                                <input class="form-control" type="text" disabled name="surname"
                                                    placeholder="" formControlName="weight"><span
                                                    class="input-group-addon" style="border: none;">lbs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xs-6" *ngIf="disableGFields">
                                            <div class="head_txt">Gender <span class="fieldMan">*</span></div>
                                            <input type="text" class="form-control" value="Male" disabled
                                                formControlName="gender">
                                        </div>
                                        <div class="col-lg-3 col-xs-6">
                                            <div class="head_txt">Date Of Birth <span class="fieldMan">*</span></div>
                                            <p-calendar [disabled]="isDisabled1"
                                                [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                formControlName="dob"></p-calendar>
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.dob.valid && (primary.controls.dob.touched || submitAttempt)">
                                                Date Of Birth is required
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-xs-4" style="padding-left: 0;">
                                            <div class="head_txt">Age </div>
                                            <input class="form-control" disabled type="text" name="surname"
                                                placeholder="" formControlName="age">
                                        </div>
                                    </div>
                                    <div class="row demomargin">
                                        <div class="col-lg-6">
                                            <div class="head_txt">Address 1 <span class="fieldMan">*</span></div>
                                            <input type="text" placeholder="" class="form-control"
                                                (blur)="InputChange('address1')" formControlName="address1">
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.address1.valid && (primary.controls.address1.touched || submitAttempt)">
                                                Address is required
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="head_txt">Address 2</div>
                                            <input type="text" placeholder="" (blur)="InputChange('address2')"
                                                class="form-control" formControlName="address2">
                                        </div>
                                    </div>
                                    <div class="row demomargin">
                                        <div class="col-lg-4">
                                            <div class="head_txt">City <span class="fieldMan">*</span></div>
                                            <input (blur)="InputChange('city')" class="form-control" type="text"
                                                appAlphabetOnly placeholder="" formControlName="city">
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.city.valid && (primary.controls.city.touched || submitAttempt)">
                                                City is required
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="head_txt">State <span class="fieldMan">*</span></div>
                                            <select (blur)="InputChange('state')" formControlName="state"
                                                class="form-control">
                                                <option value="" disabled selected [ngValue]="null">Select Your State
                                                </option>
                                                <option [value]=item.name *ngFor="let item of states">{{item.name}}
                                                </option>
                                            </select>
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.state.valid && (primary.controls.state.touched || submitAttempt)">
                                                State is required
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="head_txt">Zip Code <span class="fieldMan">*</span></div>
                                            <input class="form-control" maxlength="5" type="text" placeholder=""
                                                formControlName="zip" (blur)="InputChange('zip')"
                                                (keypress)="numberOnly($event)">
                                            <!-- [attr.disabled]="primary.controls.disabled.value?'':null" -->
                                            <div class="errormsg"
                                                *ngIf="!primary.controls.zip.valid && (primary.controls.zip.touched || submitAttempt)">
                                                Zip Code is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!============******End Primary Form*****===============>
                                <!============******Spouse Form*****===============>
                                    <div *ngFor="let spouse of demgSpouseArray;let i =index"
                                        formArrayName="SpouseArray">
                                        <div [formGroupName]="i">
                                            <div class="plan-card-header apclnt-card-header "
                                                style="margin-left: 0.05rem;">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        Spouse
                                                    </div>
                                                    <div class="col-lg-6" style="text-align: right;"
                                                        *ngIf="CheckBoxVisible">
                                                        <div><input type="checkbox"
                                                                (change)="checkBoXChange(i,'SpouseArray')"
                                                                formControlName="checkBox"> Address same as
                                                            Primary
                                                            Applicant </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row demomargin">
                                                <div class="col-lg-6">
                                                    <div class="head_txt">First Name <span class="fieldMan">*</span>
                                                    </div>
                                                    <input type="text" maxlength="25" formControlName="firstname" class="form-control">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.firstname.valid && (spouse.controls.firstname.touched || submitAttempt)">
                                                        First name is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="head_txt">Last Name <span class="fieldMan">*</span>
                                                    </div>
                                                    <input type="text" maxlength="25" formControlName="lastname" class="form-control">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.lastname.valid && (spouse.controls.lastname.touched || submitAttempt)">
                                                        Last name is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row demomargin">
                                                <div class="col-lg-3">
                                                    <div class="head_txt">Phone Number </div>
                                                    <input type="text" maxlength="12" appPhonenumberValidation
                                                        (keypress)="numberOnly($event)"
                                                        (focusout)="onfocusoutphnumber('spouse')" id="spousephnumber"
                                                        formControlName="phone" class="form-control">
                                                    <div class="errormsg" id="spousephnumbererrmsg">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="head_txt">Email <small>(Required for Member portal and Mobile App access)</small> </div>
                                                    <input type="text" formControlName="email" class="form-control">
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="head_txt">Social Security </div>
                                                    <input autocomplete="off" class="form-control" type="text"
                                                        id="social-security-spouse" maxlength="11" formControlName="ssn"
                                                        (keypress)="numberOnly($event)"
                                                        (focusout)="onspousessnFocusout($event)"
                                                        (focus)="onFocusSpouse($event)" socialSecurity
                                                        (keyup)="onTypingSocialSecurity()">
                                                    <div class="errormsg" *ngIf="numberSpouse!=''">
                                                        {{numberSpouse}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row demomargin">
                                                <div class="col-lg-3 col-xs-4">
                                                    <div class="head_txt"> Height <span class="fieldMan">*</span></div>
                                                    <input class="form-control" disabled (keypress)="numberOnly($event)"
                                                        type="text" placeholder="height" formControlName="height">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.height.valid && (spouse.controls.height.touched || submitAttempt)">
                                                        Height is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-xs-4" style="padding-left: 0;">
                                                    <div class="head_txt">Weight <span class="fieldMan">*</span>
                                                    </div>
                                                    <div class="input-group">
                                                        <input class="form-control" (keypress)="numberOnly($event)"
                                                            type="text" name="surname" disabled
                                                            formControlName="weight"><span class="input-group-addon"
                                                            style="border: none;">lbs</span>
                                                    </div>
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.weight.valid && (spouse.controls.weight.touched || submitAttempt)">
                                                        Weight is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-xs-6">
                                                    <div class="head_txt">Gender <span class="fieldMan">*</span>
                                                    </div>
                                                    <input type="text" class="form-control" value="Male"
                                                        formControlName="gender" disabled>
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.gender.valid && (spouse.controls.gender.touched || submitAttempt)">
                                                        Gender is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-xs-6">
                                                    <div class="head_txt">Date Of Birth <span class="fieldMan">*</span>
                                                    </div>
                                                    <p-calendar [disabled]="isDisabled1"
                                                        [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                        formControlName="dob"></p-calendar>
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.dob.valid && (spouse.controls.dob.touched || submitAttempt)">
                                                        Date Of Birth is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-xs-4" style="padding-left: 0;">
                                                    <div class="head_txt">Age </div>
                                                    <input class="form-control" disabled type="text" name="surname"
                                                        formControlName="age">
                                                </div>
                                            </div>
                                            <div class="row demomargin">
                                                <div class="col-lg-6">
                                                    <div class="head_txt">Address 1 <span class="fieldMan">*</span>
                                                    </div>
                                                    <input type="text"
                                                        [attr.disabled]="spouse.controls.disabled.value?'':null"
                                                        class="form-control" formControlName="address1">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.address1.valid && (spouse.controls.address1.touched || submitAttempt)">
                                                        Address is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="head_txt">Address 2</div>
                                                    <input type="text"
                                                        [attr.disabled]="spouse.controls.disabled.value?'':null"
                                                        class="form-control" formControlName="address2">
                                                </div>
                                            </div>
                                            <div class="row demomargin">
                                                <div class="col-lg-4">
                                                    <div class="head_txt">City <span class="fieldMan">*</span>
                                                    </div>
                                                    <input class="form-control" type="text" appAlphabetOnly
                                                        formControlName="city"
                                                        [attr.disabled]="spouse.controls.disabled.value?'':null">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.city.valid && (spouse.controls.city.touched || submitAttempt)">
                                                        City is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="head_txt">State <span class="fieldMan">*</span>
                                                    </div>
                                                    <select formControlName="state"
                                                        [attr.disabled]="spouse.controls.disabled.value?'':null"
                                                        class="form-control">
                                                        <option value="" disabled selected [ngValue]="null">Select Your
                                                            State</option>
                                                        <option value={{item.name}} *ngFor="let item of states">
                                                            {{item.name}}</option>
                                                    </select>
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.state.valid && (spouse.controls.state.touched || submitAttempt)">
                                                        State is required
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="head_txt">Zip Code <span class="fieldMan">*</span></div>
                                                    <input class="form-control" maxlength="5" type="text"
                                                        [attr.disabled]="spouse.controls.disabled.value?'':null"
                                                        formControlName="zip" (keypress)="numberOnly($event)">
                                                    <div class="errormsg"
                                                        *ngIf="!spouse.controls.zip.valid && (spouse.controls.zip.touched || submitAttempt)">
                                                        Zip Code is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!============******Child Form*****===============>
                                        <div *ngFor="let child of demgChildArray;let i =index"
                                            formArrayName="childArray">
                                            <div [formGroupName]="i">
                                                <div class="plan-card-header apclnt-card-header "
                                                    style="margin-left: 0.05rem;">
                                                    <div class="row">
                                                        <div class="col-lg-6" *ngIf="disableGFields">
                                                            Child {{i+1}}
                                                        </div>
                                                        <div class="col-lg-6" *ngIf="!disableGFields">
                                                            Dependent {{i+1}}
                                                        </div>
                                                        <div class="col-lg-6" style="text-align: right;"
                                                            *ngIf="CheckBoxVisible">
                                                            <div><input type="checkbox"
                                                                    (change)="checkBoXChange(i,'childArray')"
                                                                    formControlName="checkBox"> Address same
                                                                as
                                                                <span *ngIf="disableGFields">Primary</span>
                                                                <span *ngIf="!disableGFields">Guardian</span>
                                                                Applicant
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row demomargin">
                                                    <div class="col-lg-6">
                                                        <div class="head_txt">First Name <span class="fieldMan">*</span>
                                                        </div>
                                                        <input type="text" maxlength="25" formControlName="firstname"
                                                            class="form-control">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.firstname.valid && (child.controls.firstname.touched || submitAttempt)">
                                                            First name is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="head_txt">Last Name <span class="fieldMan">*</span>
                                                        </div>
                                                        <input type="text" maxlength="25" formControlName="lastname"
                                                            class="form-control">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.lastname.valid && (child.controls.lastname.touched || submitAttempt)">
                                                            Last name is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row demomargin">
                                                    <div class="col-lg-3">
                                                        <div class="head_txt">Phone Number
                                                        </div>
                                                        <input type="text" maxlength="12"
                                                            (focusout)="onfocusoutphnumber('child',i, $event)"
                                                            id="{{'childphnumber' +i}}" appPhonenumberValidation
                                                            (keypress)="numberOnly($event)" formControlName="phone"
                                                            class="form-control">
                                                        <div class="errormsg" id="{{ 'childpherror' + i}}"></div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="head_txt">Email <small>(Required for Member portal and Mobile App access)</small>
                                                        </div>
                                                        <input type="text" formControlName="email" class="form-control">
                                                        <div class="errormsg"
                                                            *ngIf=" (child.controls.email.dirty && child.controls.email.touched ) && child.controls.email.hasError('pattern')">
                                                            Please enter a valid Email Address
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="head_txt">Social Security </div>
                                                        <input autocomplete="off" class="form-control" type="text"
                                                            maxlength="11" attr.id="social-security-child-{{i}}"
                                                            formControlName="ssn" (focus)="onFocusChild($event)"
                                                            (focusout)="onchildssnFocusout($event)"
                                                            (keypress)="numberOnly($event)" socialSecurity
                                                            (keyup)="onTypingSocialSecuritychild(i, $event)">
                                                        <div class="errormsg" id="{{ 'error' + i}}"></div>
                                                    </div>
                                                </div>
                                                <div class="row demomargin">
                                                    <div class="col-lg-3 col-xs-4">
                                                        <div class="head_txt"> Height <span class="fieldMan">*</span>
                                                        </div>
                                                        <input class="form-control" disabled
                                                            (keypress)="numberOnly($event)" type="text"
                                                            placeholder="height" formControlName="height">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.height.valid && (child.controls.height.touched || submitAttempt)">
                                                            Height is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 col-xs-4" style="padding-left: 0;">
                                                        <div class="head_txt">Weight <span class="fieldMan">*</span>
                                                        </div>
                                                        <div class="input-group">
                                                            <input class="form-control" disabled
                                                                (keypress)="numberOnly($event)" type="text"
                                                                name="surname" formControlName="weight"><span
                                                                class="input-group-addon"
                                                                style="border: none;">lbs</span>
                                                        </div>
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.weight.valid && (child.controls.weight.touched || submitAttempt)">
                                                            Weight is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-xs-6">
                                                        <div class="head_txt">Gender <span class="fieldMan">*</span>
                                                        </div>
                                                        <input type="text" class="form-control" disabled value="Male"
                                                            formControlName="gender">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.gender.valid && (child.controls.gender.touched || submitAttempt)">
                                                            Gender is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-xs-6">
                                                        <div class="head_txt">Date Of Birth <span
                                                                class="fieldMan">*</span>
                                                        </div>
                                                        <p-calendar [disabled]="isDisabled1"
                                                            [style]="{'width': '100%','font-size':'14px','background-color':'#dededee8','display': 'inline-flex','height':'35px'}"
                                                            formControlName="dob"></p-calendar>
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.dob.valid && (child.controls.dob.touched || submitAttempt)">
                                                            Date Of Birth is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-1 col-xs-4" style="padding-left: 0;">
                                                        <div class="head_txt">Age </div>
                                                        <input class="form-control" disabled type="text" name="surname"
                                                            formControlName="age">
                                                    </div>
                                                </div>
                                                <div class="row demomargin">
                                                    <div class="col-lg-6">
                                                        <div class="head_txt">Address 1 <span class="fieldMan">*</span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            [attr.disabled]="child.controls.disabled.value?'':null"
                                                            formControlName="address1">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.address1.valid && (child.controls.address1.touched || submitAttempt)">
                                                            Address is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="head_txt">Address 2</div>
                                                        <input type="text" class="form-control"
                                                            [attr.disabled]="child.controls.disabled.value?'':null"
                                                            formControlName="address2">
                                                    </div>
                                                </div>
                                                <div class="row demomargin">
                                                    <div class="col-lg-4">
                                                        <div class="head_txt">City <span class="fieldMan">*</span>
                                                        </div>
                                                        <input class="form-control" type="text" appAlphabetOnly
                                                            [attr.disabled]="child.controls.disabled.value?'':null"
                                                            formControlName="city">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.city.valid && (child.controls.city.touched || submitAttempt)">
                                                            City is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5">
                                                        <div class="head_txt">State <span class="fieldMan">*</span>
                                                        </div>
                                                        <select formControlName="state" class="form-control"
                                                            [attr.disabled]="child.controls.disabled.value?'':null">
                                                            <option value="" disabled selected [ngValue]="null">
                                                                Select Your State</option>
                                                            <option value={{item.name}} *ngFor="let item of states">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.state.valid && (child.controls.state.touched || submitAttempt)">
                                                            State is required
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="head_txt">Zip Code <span class="fieldMan">*</span>
                                                        </div>
                                                        <input class="form-control" maxlength="5" type="text"
                                                            [attr.disabled]="child.controls.disabled.value?'':null"
                                                            formControlName="zip" (keypress)="numberOnly($event)">
                                                        <div class="errormsg"
                                                            *ngIf="!child.controls.zip.valid && (child.controls.zip.touched || submitAttempt)">
                                                            Zip Code is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                    </div>
                    <div class="row demomargin">
                        <div class="col-lg-12" style="text-align: right;">
                            <button type="button" class="btn btn-success"
                                (click)="submitPrimaryForm(auxConfigform.value)">Next</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>