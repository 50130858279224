import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TestService } from '../../services/behaviourService';
import { ConfigService } from '../../services/config.service';
import { SharingService } from '../../services/sharing.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $;
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-healthbasedquestions',
    templateUrl: './healthbasedquestions.component.html',
    styleUrls: ['./healthbasedquestions.component.scss']
})
export class HealthbasedquestionsComponent implements OnInit {
    applicant: any;
    cart_id: any;
    user_id: any;
    cartProducts: any;
    memberdata: any;
    selectedLink: string = "";
    healthFormArray: any[] = [];
    healthFormArray1: any[] = [];
    healthFormArrayunchecked: any[] = [];
    Form: FormGroup;
    healthQuestions: any;
    newarr = [];
    newarr2 = [];
    newarr3 = [];
    finalarr = [];
    arr = []
    arr1 = []
    errMsg: string;
    questions: any;
    questionsData: any;
    data: any;
    healthissue: any;
    mydata: any;
    showQuestionsmsg: boolean = false;
    mH_id: any;
    healthArray: any[] = [];
    selectedQuestions: any[] = [];
    selectedQuestionsprevbtn: any[] = [];
    agentInfo: any;
    companyId: any;
    submitAttempt: boolean = false;
    cartFee: any;
    cartData: any;
    memberDiscount: any;
    bmiinfo: any;
    additionalMember: any;
    newArr: any = [];
    newmembercheckArr: any = [];
    newmemberlastcheckArr: any = []
    mgalert: any;
    data99: any;
    responseNewArr: any = [];
    responseNewArr1: any = [];
    responseNewArr2: any = [];
    companyInfo: any;
    oneTimeFee: any;
    monthlyContribution: any;
    monthlyContributionStartDate: any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private objectService: TestService,
        private configService: ConfigService,
        private services: SharingService,
        private http: HttpClient,
        private toastr: ToastrService
    ) {
        this.services.clientData.subscribe((data) => {
            if (data != '') {
                this.companyInfo = data;
                this.companyId = this.companyInfo.company_id;
                // this.CompanyName=  this.companyInfo.company_name
            }
        })
        this.agentInfo = JSON.parse(sessionStorage.getItem('agentinfo'));
        this.questionsData = JSON.parse(sessionStorage.getItem('questions'));
        this.objectService.questionsObject.subscribe(result => {
            if (result != '') {
                this.mydata = result ? result.data : null;
                this.data99 = result.data;
            }
        })
        this.objectService.demographyObject.subscribe(result => {
            this.applicant = result;
            this.memberdata = this.applicant.member_personal_data;
            this.cart_id = this.applicant.cart_data[0].id;
            this.user_id = this.applicant.member_personal_data[0].user_id;
        })
        var applicantData= JSON.parse(localStorage.getItem('appCart'));
        this.cartFee = applicantData.cart_fee;
        this.cartData = applicantData.cart_data;
        this.memberDiscount = applicantData.agent_cart_discount;
        this.oneTimeFee = applicantData.a2c_total_one_time_fee;
        this.cartProducts = applicantData.cart_products;
        this.monthlyContribution = applicantData.monthly_contribution;
        this.monthlyContributionStartDate = applicantData.member_applications_data[0].monthly_contribution_start_date;
    }

    ngOnInit(): void {
        this.Form = this.formBuilder.group({
            healthissues: this.formBuilder.array([]),
            acknowledgeCheck: ['', Validators.required],
            acknowledgeCheck1: ['', Validators.required]
        });
        this.getHealthBasedQts();
        var questionBack = localStorage.getItem('hQuestions');
        if (questionBack == "healthQuestions") {
            this.getQuestionsInfo();
        }
    }
    //======getquestion first flow//
    getQuestionsInfo() {
        var url = 'GetMemberHealthIssue/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id
        this.services.getData(url).subscribe((obj: any) => {
            if (obj.status == 'success') {
                this.mydata = obj.data;
                this.data99 = obj.data
                this.getHealthBasedQts();
            }
        }, err => {
            console.log(err)
        })
    }
    //==========yes No check checkbox 1 to 4 questions=============//
    onChangeQts(value, isChecked: boolean, p) {
        if (isChecked == true) {
            if (value) {
                for (var i = 0; i < this.healthQuestions[p].healthissue.length; i++) {
                    if (this.healthQuestions[p].healthissue[i].health_issue_id == value.health_issue_id) {
                        this.healthQuestions[p].healthissue[i].Checked = true;
                        this.healthQuestions[p].healthissue[i].disabled = false;
                    }
                    else {
                        this.healthQuestions[p].healthissue[i].Checked = false;
                        this.healthQuestions[p].healthissue[i].disabled = true;
                    }
                }
            }
            value.health_issue_id = value.health_issue_id
            value.active = 1;
            this.newArr.push(value)
        } else {
            for (var i = 0; i < this.healthQuestions[p].healthissue.length; i++) {
                if (this.healthQuestions[p].healthissue[i].health_issue_id == value.health_issue_id) {
                    this.healthQuestions[p].healthissue[i].Checked = false;
                    this.healthQuestions[p].healthissue[i].disabled = false;
                }
                else {
                    this.healthQuestions[p].healthissue[i].Checked = false;
                    this.healthQuestions[p].healthissue[i].disabled = false;
                }
            }
            if (this.responseNewArr.length > 0) {
                let index = this.responseNewArr.findIndex(el => el.id == value.id)
                if (this.responseNewArr.length > 0) {
                    value.member_health_issue_id = this.responseNewArr[index].member_health_issue_id;
                    value.active = 0;
                    this.newArr.push(value);
                }
            }
            else {
                let index = this.newArr.findIndex(el => el.health_issue_id == value.health_issue_id)
                if (index > -1) {
                    this.newArr.splice(index, 1);
                }
            }
        }
    }
    //=================Member checkbox upto 1 to 4 questions==========================//
    onChange(value, isChecked: boolean, p) {
        this.mH_id;
        if (isChecked == true) {
            value.health_issue_id = "";
            value.active = 1
            this.newmembercheckArr.push(value)
            for (var i = 0; i < this.healthQuestions[p].healthissue.length; i++) {
                if (this.healthQuestions[p].healthissue[i].member_id == value.member_id) {
                    this.healthQuestions[p].healthissue[i].Checked = true;
                    this.healthQuestions[p].healthissue[0].disabled = true;
                    this.healthQuestions[p].healthissue[0].Checked = false;
                    this.healthQuestions[p].healthissue[i].disabled = false;
                }
            }
        }
        else {
            for (var i = 0; i < this.healthQuestions[p].healthissue.length; i++) {
                if (this.healthQuestions[p].healthissue[i].member_id == value.member_id) {
                    this.healthQuestions[p].healthissue[i].Checked = false;
                    this.healthQuestions[p].healthissue[0].disabled = false; //true
                    this.healthQuestions[p].healthissue[0].Checked = false;
                    this.healthQuestions[p].healthissue[i].disabled = false;
                }
            }
            if (this.responseNewArr1.length > 0) {
                let index = this.responseNewArr1.findIndex(el => el.member_id == value.member_id)
                if (this.responseNewArr1.length > 0) {
                    value.member_health_issue_id = this.responseNewArr1[index].member_health_issue_id;
                    value.active = 0;
                    this.newmembercheckArr.push(value);
                    console.log(this.newmembercheckArr)
                }
            } else {
                let k = this.healthQuestions[p].healthissue.findIndex(el => el.Checked == true && this.healthQuestions[p].healthissue[0].Checked == false)
                if (k == -1) {
                    this.healthQuestions[p].healthissue[0].disabled = false;
                }
                let index = this.newmembercheckArr.findIndex(el => el.member_id == value.member_id)
                if (index > -1) {
                    this.newmembercheckArr.splice(index, 1);
                }
            }
        }
    }
    //=================check box 5th question=================//
    onChangeMember(value1, member, isChecked: boolean, index) {
        if (isChecked == true) {
            value1.health_issue_id = value1.health_issue_id;
            value1.member_id = member.member_id;
            value1.active = 1
            this.newmemberlastcheckArr.push(value1)
        } else {
            if (this.responseNewArr2.length > 0) {
                var Z = this.responseNewArr2.filter(x => x.member_id == member.member_id);
                let index = Z.findIndex(el => el.health_issue_id == value1.health_issue_id)
                if (Z.length > 0) {
                    value1.member_health_issue_id = Z[index].member_health_issue_id;
                    value1.active = 0;
                    value1.member_id = Z[index].member_id;
                    this.newmemberlastcheckArr.push(value1);
                } else {
                }
            } else {
                let index = this.newmemberlastcheckArr.findIndex(el => el.health_issue_id == value1.health_issue_id)
                if (index > -1) {
                    this.newmemberlastcheckArr.splice(index, 1);
                }
            }
        }
    }
    get ordersFormArray() {
        return this.Form.controls.healthissues as FormArray;
    }
    getHealthBasedQts() {
        var url = 'GetHealthQuestions/' + this.companyId + '/' + this.memberdata[0].member_id;
        this.services.getData(url).subscribe((Object: any) => {
            this.healthQuestions = Object.data;
            this.mgalert = Object.mgalert;
            setTimeout(() => {
                document.getElementById("openitem1").click();
            }, 100);
            if (this.data99) {
                for (var i = 0; i < this.data99.length; i++) {
                    var index = this.healthQuestions.findIndex(x => x.id == this.data99[i].health_id);
                    if (index > -1) {
                        if (this.healthQuestions[index].memberhealthissue) {
                            var memidIndex = this.healthQuestions[index].memberhealthissue.findIndex(x => x.member_id == this.data99[i].member_id)
                            var memhealthindex = this.healthQuestions[index].memberhealthissue[memidIndex].healthissue.findIndex(x => x.health_issue_id == this.data99[i].health_issue_id)
                            this.healthQuestions[index].memberhealthissue[memidIndex].healthissue[memhealthindex].Checked = true;
                            var obj = {
                                member_health_issue_id: this.data99[i].member_health_issue_id,
                                member_id: this.data99[i].member_id,
                                health_issue_id: this.data99[i].health_issue_id,
                                active: 1,
                                health_id: this.data99[i].health_id
                            }
                            this.responseNewArr2.push(obj);
                            console.log(this.responseNewArr2)
                        }
                        else {
                            var q1 = true
                            if (this.data99[i].health_issue_id) {
                                q1 = false
                                var index2 = this.healthQuestions[index].healthissue.findIndex(x => x.health_issue_id == this.data99[i].health_issue_id)
                                this.healthQuestions[index].healthissue[index2].Checked = true;
                                for (var j = 0; j < this.healthQuestions[index].healthissue.length; j++) {
                                    if (j == index2) {
                                        this.healthQuestions[index].healthissue[j].Checked = true;
                                    }
                                    else if (j != index2) {
                                        this.healthQuestions[index].healthissue[j].disabled = true;
                                    }
                                }
                                var objR1 = {
                                    member_health_issue_id: this.data99[i].member_health_issue_id,
                                    member_id: this.data99[i].member_id,
                                    health_issue_id: this.data99[i].health_issue_id,
                                    active: 1,
                                    health_id: this.data99[i].health_id
                                }
                                this.responseNewArr.push(objR1);
                                console.log(this.responseNewArr)
                            }
                            else {
                                if (this.data99[i].health_issue_id == null) {
                                    var mem = this.healthQuestions[index].healthissue.findIndex(x => x.health_issue_id != null)
                                    var m = this.healthQuestions[index].healthissue[mem].health_issue_id
                                    var index2 = this.healthQuestions[index].healthissue.findIndex(x => x.member_id == this.data99[i].member_id && x.health_issue_id != m)
                                    this.healthQuestions[index].healthissue[index2].Checked = true;
                                    for (var j = 0; j < this.healthQuestions[index].healthissue.length; j++) {
                                        if (j == index2) {
                                            this.healthQuestions[index].healthissue[j].Checked = true;
                                        }
                                        else if (j == 0) {
                                            this.healthQuestions[index].healthissue[j].disabled = true;
                                        }
                                    }
                                    var obj1 = {
                                        member_health_issue_id: this.data99[i].member_health_issue_id,
                                        member_id: this.data99[i].member_id,
                                        health_issue_id: this.data99[i].health_issue_id,
                                        active: 1,
                                        health_id: this.data99[i].health_id
                                    }
                                    this.responseNewArr1.push(obj1);
                                }
                            }
                        }
                    }
                }
            }
        }, err => {
            console.log(err)
        },
            () => {
            })
    }
    submitHealthQts(va) {
        this.submitAttempt = true
        if (this.Form.valid) {
            if (va.acknowledgeCheck == true && va.acknowledgeCheck1 == true) {
                var newbodyGetresponse = this.responseNewArr1.concat(this.responseNewArr, this.responseNewArr2);
                var newbody = this.newArr.concat(this.newmembercheckArr, this.newmemberlastcheckArr);
                if (newbody.length > 0) {
                    for (var i = 0; i < newbody.length; i++) {
                        let obj = {
                            member_id: newbody[i].member_id,
                            health_issue_id: newbody[i].health_issue_id,
                            member_health_issue_id: newbody[i].member_health_issue_id,
                            health_id: newbody[i].health_id,
                            active: newbody[i].active
                        }
                        this.healthArray.push(obj);
                    }
                } else {
                    if (newbodyGetresponse.length > 0) {
                        for (var i = 0; i < newbodyGetresponse.length; i++) {
                            let obj = {
                                member_id: newbodyGetresponse[i].member_id,
                                health_issue_id: newbodyGetresponse[i].health_issue_id,
                                member_health_issue_id: newbodyGetresponse[i].member_health_issue_id,
                                health_id: newbodyGetresponse[i].health_id,
                                active: newbodyGetresponse[i].active
                            }
                            this.healthArray.push(obj);
                        }
                    }
                    else {
                        this.toastr.error(" Please select your health diseases.")
                    }
                }
                var finalObject: any = {}
                finalObject.member_personal_data = this.healthArray;
                if (finalObject.member_personal_data.length > 0) {
                    let body = {
                        company_id: this.companyId,
                        member_personal_data: this.healthArray
                    }
                    var url = 'CreateMemberHealthIssue';
                    console.log(body);
                    this.services.postData(url, body).subscribe((obj) => {
                        this.services.pushQuestionsList(this.selectedQuestions);
                        this.router.navigate(['A2C/payment']);
                    }, err => {
                        console.log(err)
                    })
                }
            } else {
                this.submitAttempt = true
            }
        }
    }
    changeToDemography(v) {
        sessionStorage.setItem('applicantData', v);
        var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
        this.services.getData(url).subscribe((Object: any) => {
            this.objectService.addCartPlan(Object);
            this.router.navigate(['/A2C/demographic/' + localStorage.getItem('a2cUrl')]);
        }, err => {
            console.log(err)
        })
    }
}
