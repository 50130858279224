import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit {

  constructor(   private objectService: TestService,) { }
  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }

}
