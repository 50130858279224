<form [formGroup]="cardDetails">
  <div class="row form-group">
    <div class="col-md-8">
      <input class="form-control" placeholder="'Card Number'" maxlength="16" customize [numbersOnly]="true"
        [preventCopy]="true" [preventPaste]="true" formControlName="cardnumber">
      <small *ngIf="controls.cardnumber.touched && controls.cardnumber.invalid" class="text-danger">Card Number
        is required</small>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-5 col-xs-5">
          <input class="form-control" placeholder="'MM'" maxlength="2" customize [numbersOnly]="true"
            [preventCopy]="true" [preventPaste]="true" formControlName="month">

        </div>
        <div class="col-md-2 col-xs-2">
          <span class="slash_symbol">/</span>
        </div>
        <div class="col-md-5 col-xs-5">
          <input class="form-control" placeholder="'YY'" maxlength="2" customize [numbersOnly]="true"
            [preventCopy]="true" [preventPaste]="true" formControlName="year">
        </div>
      </div>
      <small *ngIf="controls.month.touched && controls.month.invalid && controls.year.touched && controls.year.invalid"
        class="text-danger">Expiry Date is required</small>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <input class="form-control" placeholder="'CVV'" maxlength="4" customize [numbersOnly]="true"
          [preventCopy]="true" [preventPaste]="true" formControlName="cvv">
          <small class="text-danger" *ngIf="controls.cvv.touched && controls.cvv.invalid ">CVV is required</small>
      </div>
    </div>
  </div>
</form>
