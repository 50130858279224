import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
//import { ENDPOINT_URL } from './dataservices.constants';
import { EndpointConfig } from './endpoint-config';
import{ENDPOINT_URL} from '../dataservices/dataservices.constants'
@Injectable({
    providedIn: 'root'
})

export class GetStatesService {
    constructor(private http: EndpointConfig) { }
    get() {
        return this.http.get(ENDPOINT_URL.LIST_USA_STATES).pipe(map((data: any) => { return data; }))
    }
}