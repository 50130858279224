<div class="loader" *ngIf="loader">
  <div id="loading"></div>
</div>
<section>
  <div class="container">
    <h4 class="h1">
      Welcome to Fellowship HealthShare!
    </h4>
    <h5 class="healthcaretxt">
    </h5>
    <div class="clearfix">
      <!-- <img class="img2" src="assets/images/healthcare.png" alt="Pineapple" width="180" height="170"> -->
      <p style="line-height: 3rem;margin-top: 2.5rem;">
        We are a Healthcare Sharing Organization designed by people who have been there and done that! As former
        for-profit and non-profit employers, we know how important employee benefits are, that’s why we have
        designed state of the art Membership Programs for your employees. We should know, we are members ourselves!
        Enrolling your employees is easy, but first we’ll need to gather some information from you and appoint you
        with a point of contact to ensure that everything is done seamlessly for you.
      </p>
    </div>
    <hr>
    <h5 class="healthcaretxt">
      Please fill out our forms below and someone will get in touch with you ASAP, or call the number
      provided to get the process stated!
    </h5>
    <div class="h1">
      Your Organization
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="details">
          <img class="img22" src="assets/images/healthcare.png" alt="" width="170" height="170">
        </div>
      </div>
      <div class="col-sm-8 formcnt">
        <div class="contact_form">
          <form [formGroup]="newForm">
            <div class="row">
              <div class="col-lg-6">
                <div class="head_txt1">Organization Name<span class="fieldMan">*</span></div>
                <input class="form-control" type="text" formControlName="orgname" placeholder="">
                <div class="errormsg"
                  *ngIf="!newForm.controls.orgname.valid && (newForm.controls.orgname.touched || submitAttempt)">
                  Organization Name is required
                </div>
              </div>
              <div class="col-lg-6">
                <div class="head_txt1">Primary Contact Name<span class="fieldMan">*</span></div>
                <input class="form-control" type="text" placeholder="" formControlName="pContactName">
                <div class="errormsg"
                  *ngIf="!newForm.controls.pContactName.valid && (newForm.controls.pContactName.touched || submitAttempt)">
                  Primary Contact Name is required
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="head_txt1"> Address <span class="fieldMan">*</span></div>
                <input class="form-control" type="text" placeholder="" formControlName="address">
                <div class="errormsg"
                  *ngIf="!newForm.controls.address.valid && (newForm.controls.address.touched || submitAttempt)">
                  Address is required
                </div>
              </div>
              <div class="col-lg-12">
                <div class="head_txt1"> Phone Number <span class="fieldMan">*</span></div>
                <input class="form-control" maxlength="12" (keyup)="onkeyupPhone($event)" id="phnumber"
                  (keypress)="numberOnly($event)" (focusout)="onfocusoutphnumber('primary')" class="form-control"
                  appPhonenumberValidation type="text" placeholder="" formControlName="phone">
                <div class="errormsg"
                  *ngIf="!newForm.controls.phone.valid && (newForm.controls.phone.touched || submitAttempt)">
                  Phone Number is required
                </div>
                <div class="errormsg" id="phnumbererrmsg">
                </div>
              </div>
              <div class="col-lg-12">
                <div class="head_txt1"> Email Address <span class="fieldMan">*</span></div>
                <input class="form-control" type="text" placeholder="" formControlName="email">
                <div class="errormsg"
                  *ngIf="!newForm.controls.email.valid && (newForm.controls.email.touched || submitAttempt)">
                  Email Address is required
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-lg-12" style="text-align: right;">
                <button type="button" (click)="submit(newForm.value)" class="btn btn-success">Send <i
                    class="fa fa-paper-plane-o"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <h3 class="h1">
    <hr>
    Your Fellowship Point of Contact
  </h3>
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="details1">
          <p>
            We are so excited to hear from you!
          </p>
          <p>
            If you wish to reach out to us to get the ball rolling.please feel free!
          </p>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="tablelayout">
          <div>
            <ul class="detail-features">
              <li><strong>Contact Name: </strong>
                <p>Matt Manley
                </p>
              </li>
              <li><strong>Contact Position: </strong>
                <p>Executive Director
                </p>
              </li>
              <li><strong>Email Address: </strong>
                <p>mattmanley@fellowshiphealthshare.org
                </p>
              </li>
              <li><strong>Personal Phone Number: </strong>
                <p>800-956-5081
                </p>
              </li>
              <li><strong> Secondary Contact: </strong>
                <p>sales@fellowshiphealthshare.org
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>