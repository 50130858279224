import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hc2u-details',
  templateUrl: './hc2u-details.component.html',
  styleUrls: ['./hc2u-details.component.scss']
})
export class Hc2uDetailsComponent implements OnInit {
  showModal: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  show(){
    this.showModal = true;
  }

}
