<div class="container m-top" style="padding: 0 0 30px 0;">
  <div class="applicationProgress">
    <div class="appProgStep " style="z-index:6;" id="app2">Add-On/Supplemental Products
    </div>
    <div class="appProgStep " style="z-index:5; padding-left: 30px;" id="app1">Demographics &amp; Dependents
    </div>
    <div class="appProgStep " style="z-index:3;" id="app3">Health-Based Questions</div>
    <div class="appProgStep active" style="z-index:2;" id="app4">Payment Details</div>
    <div class="appProgStep appProgStep-last" style="z-index:1;" id="app5">
      <span>Summary</span>
    </div>
  </div>
  <div class="container m-top">
    <div class="row demomargin">
      <div class="col-lg-4 col-md-12 padding_demo">
        <app-billing-details [applicantData]="applicantData"></app-billing-details>
      </div>
      <div class="col-lg-8 col-md-12 padding_demo1">
        <app-payment-section></app-payment-section>
      </div>
    </div>
  </div>
</div>