import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from '../services/behaviourService';
import { SharingService } from '../services/sharing.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  applicant: any;
  cartProducts: any;
  memberdata: any;
  paymentData: any;
  orderDetails: any;
  loader: boolean = false;
  cardType: boolean = false;

  errMsg: any;
  memer: any;
  order_id: any;
  expdate: any;
  year: any;
  month: any;
  card: any;
  cardData: any;
  account_type: string;
  signature: any;
  date = new Date();
  applicant_type: any;
  errorMessage: any;
  data: any;
  displayplane: boolean = true;
  checkedSignature: boolean = false;
  applicantname: any;
  companyId: any;
  cartFee: any;
  cartData: any;
  memberDiscount: any;
  bmiinfo: any;
  additionalMember: any;

  signatureverify: boolean;
  showCheckboxError = "";
  showSignatureVerifyError = "";
  showSignatureVerifyError1 = "";
  companyName: any;
  pendingStatus: any;
  companyInfo: any;
  oneTimeFee: any;
  groupID: any;
  dispalyGuardian: boolean;
  constructor(
    private router: Router,
    private objectService: TestService,
    private services: SharingService,
    private toastr: ToastrService
  ) {
    localStorage.removeItem('planupdate')
    localStorage.removeItem('summaryPlanupdate');
    this.services.clientData.subscribe((data) => {
      
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.companyName = this.companyInfo.company_name;
      }
    })

    this.objectService.plansSObject.subscribe(result => {
      
      var data = result;
      this.data = data.cart_products;
      this.displayplane = false
    });
    this.objectService.paymentObject.subscribe(result => {
      this.applicant = result;
      this.groupID = this.applicant.member_personal_data[0].group_id;//G2C flow
      this.cartFee = this.applicant.cart_fee;
      this.cartData = this.applicant.cart_data;
      this.memberDiscount = this.applicant.agent_cart_discount;
      this.bmiinfo = this.applicant.cart_bmi;
      this.oneTimeFee = this.applicant.total_one_time_fee;
      this.additionalMember = this.applicant.additional_member_fee
      this.applicant_type = this.applicant.member_personal_data[0].firstname + "  " + this.applicant.member_personal_data[0].lastname
      this.cartProducts = this.applicant.cart_products;
      this.displayplane = true
      this.orderDetails = this.applicant.order_details;
      this.memberdata = this.applicant.member_personal_data;
      this.applicantname = this.memberdata[0].firstname + " " + this.memberdata[0].lastname;
      if (this.orderDetails != undefined && this.orderDetails.length > 0) {
        this.order_id = this.orderDetails[0].order_id;
        this.getSummeryDetails(this.order_id);
      } else {
        this.order_id = localStorage.getItem('order_id');//g2c flow
        this.getSummeryDetails(this.order_id);
      }
    })
  }
  ngOnInit(): void {
  }
  backToPlanes() {
    localStorage.setItem('planupdate', 'updateplan')
    localStorage.setItem('summaryPlanupdate', 'summarytoPlan')
    if (this.memberdata[0].relation == "Guardian") {
      var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[1].member_id + '/' + this.applicant.cart_data[0].id;
    } else {
      var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
    }
    this.services.getData(url).subscribe((Object: any) => {
      
      this.objectService.demoToA2c(Object);
      if (this.groupID != null) {
        this.router.navigate(['dynamicplan/' + localStorage.getItem('urlGroupName')]);
      } else {
        this.router.navigate(['dynamicplan']);
      }
    }, err => {
      console.log(err);
      this.toastr.error('Something went wrong. Please try again later.');
    })
  }
  getSummeryDetails(order_id) {
    var url = 'GetSharingOrder/' + this.companyId + '/' + order_id;
    this.services.getData(url).subscribe((Object: any) => {
      if (Object.status == 'success') {
        this.memberdata = Object.member_personal_data;
        if (this.memberdata[0].relation == 'Guardian') {
          this.dispalyGuardian = true;
        } else {
          this.dispalyGuardian = false;
        }
        this.applicantname = this.memberdata[0].firstname + " " + this.memberdata[0].lastname
        for (let i = 0; i < this.memberdata.length; i++) {
          var ssn = "***" + '-' + '**' + '-';
          if (this.memberdata[i].ssn4) {
            this.memberdata[i].ssn4 = ssn + this.memberdata[i].ssn4;
          }
          if (this.memberdata[i].phone) {
            this.memberdata[i].phone = this.memberdata[i].phone.substring(0, 3) + '-' + this.memberdata[i].phone.substring(3, 6) + '-' + this.memberdata[i].phone.substring(6, 10);
          }
          if (this.memberdata[i].height != undefined) {
            var height = this.memberdata[i].height.toString();
            var n = height.toString().includes(".");
            if (n == false) {
              var str2 = ".0";
              var height = height.toString().concat(str2);
            }
            const Height = height.toString();
            var inch = height.substring(Height.lastIndexOf('.') + 1);
            var ft = height.substring(0, Height.lastIndexOf('.'));
            this.memberdata[i].height = ft + "'" + " " + inch + "''";
          }
        }
        this.paymentData = Object.paymentdata;
        if (this.paymentData != null || this.paymentData != undefined) {
          if (this.paymentData[0].payment_type == "CARD") {
            this.cardType = false;
            this.cardData = this.paymentData[0].card;
            // this.expdate = this.paymentData[0].exp;
            // const dateString = this.expdate.toString();
            // this.year = dateString.substring(dateString.length - 2);
            // this.month = dateString.substring(0, 2);
          }
          if (this.paymentData[0].payment_type == "BANK") {
            this.cardType = true;
            if (this.paymentData[0].account_type == "PS") {
              this.account_type = "Savings";
            }
            else {
              this.account_type = "Checking";
            }
          }
        }
      }
    }, err => {
      this.toastr.error('Something went wrong. Please try again later.');
      console.log(err)
    })
  }
  onfocusoutSignature() {
    this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
    if (this.applicantname.toUpperCase() === this.signature.toUpperCase()) {
      this.showSignatureVerifyError = '';
      this.showSignatureVerifyError1 = '';
    }
  }
  finalSubmit() {
    if (!this.checkedSignature) {
      this.showCheckboxError = "Please check signature checkbox";
      setTimeout(() => {
        this.showCheckboxError = "";
      }, 4000);
    }
    if (this.checkedSignature && !this.signatureverify) {
      this.showSignatureVerifyError = 'Your Signature Must Match ';
      this.showSignatureVerifyError1 = 'Your First Name and Lastname is:' + " " + " " + this.applicantname;
      setTimeout(() => {
        this.showSignatureVerifyError = "";
      }, 9000);
    }
    if (this.checkedSignature && this.signature != undefined) {
      if (this.applicantname.toUpperCase() === this.signature.toUpperCase()) {
        this.signatureverify = true;
      }
      else {
        this.signatureverify = false;
      }
    }
    if (this.checkedSignature && this.signatureverify) {
      this.showSignatureVerifyError = "";
      var orderId = this.order_id;
      let body = {
        order_id: orderId,
        acknowledge_by: this.signature,
        company_id: this.companyId,
        company_name: this.companyName
      };
      this.loader = true;
      var url = 'ProcessOrderSharing';
      this.services.postData(url, body).subscribe((Object: any) => {
        this.services.pushOrderDetails(Object)
        if (Object.status == 'success') {
          this.loader = false;
          this.router.navigate(['thankyou']);
          var plansAndProducts = "";
          this.objectService.changeUsername(plansAndProducts);
          // localStorage.removeItem('order_id');
        }
        if (Object.status == 'failure') {
          this.loader = false;
          this.errMsg = Object.order_details[0].message;
          this.toastr.error(this.errMsg);
        }
        if (Object.status == 'pending') {
          this.pendingStatus = Object.order_details[0].message;
          this.toastr.warning(this.pendingStatus);
        }
      }, err => {
        console.log(err);
        this.loader = false;
        this.toastr.error('Something went wrong. Please try again later.');
        // this.errorMessage = err.error.acknowledge_by[0];
        setTimeout(() => {
          this.errorMessage = ""
        }, 3000);
      })
    }
  }

  onCheckbox(e) {
    if (e.target.checked == true) {
      this.checkedSignature = true;
      this.showCheckboxError = "";
    }
    else {
      this.checkedSignature = false;
    }
  }
  changeToDemography() {
    sessionStorage.setItem('applicantData', '1');
    if (this.memberdata[0].relation == "Guardian") {
      var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[1].member_id + '/' + this.applicant.cart_data[0].id;
    } else {
      var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
    }
    this.services.getData(url).subscribe((Object: any) => {
      this.objectService.addCartPlan(Object);
      this.router.navigate(['demographic']);
    }, err => {
      console.log(err);
      this.toastr.error('Something went wrong. Please try again later.');
    })
  }
  changeToPaymeny() {
    if (this.memberdata[0].relation == "Guardian") {
      var url = 'GetPayment/' + this.companyId + '/' + this.memberdata[1].member_id;
    } else {
      var url = 'GetPayment/' + this.companyId + '/' + this.memberdata[0].member_id;
    }
    this.services.getData(url).subscribe((Object: any) => {
      this.objectService.demographyH(Object);
      this.router.navigate(['paymentdetails']);
    }, err => {
      console.log(err);
      this.toastr.error('Something went wrong. Please try again later.');
    })
  }
}
