<section>

    <div class="container">
        <h1>State Specific Notices </h1>
        <div>
            <b>Alabama Code Title 22-6A-2:</b>
            <p class="notices">
                <strong> Notice:</strong> The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its guidelines nor plan of operation is an insurance policy. Whether anyone chooses to assist you with
                your
                medical bills will be totally voluntary because no other participant will be compelled by law to
                contribute
                toward your medical bills. As such, participation in the organization or a subscription to any of its
                documents
                should never be considered to be insurance. Regardless of whether you receive any payment for medical
                expenses
                or whether this organization continues to operate, you are always personally responsible for the payment
                of your
                own medical bills.
            </p>
        </div>

        <div>
            <b style="font-weight: bold;  color: #6551a0; margin-bottom: 0px; "> Alaska: </b>
            <p style="font-weight: bold;  color: #6551a0; margin-top: 0px; font-size: 13px; margin-bottom: 0px;">
                Statute 21.03.021(k)
            </p>

            <p class="notices"> <strong> Notice:</strong> The organization coordinating the sharing of medical expenses
                is not an
                insurance company, and neither its guidelines nor plan of operation is an insurance policy.
                Whether anyone chooses to assist you with your medical bills will be totally voluntary
                because no other participant will be compelled by law to contribute toward your medical bills.
                Participation in the organization or a subscription to any of its documents should never be
                considered to be insurance. Regardless of whether you receive a payment for medical expenses
                or whether this organization continues to operate, you are always personally responsible for
                the payment of your own medical bills.
            </p>
        </div>

        <div>
            <b>Arizona Statute 20-122:</b><br>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company and
                the
                ministry’s
                guidelines and plan of operation are not an insurance policy. Whether anyone chooses to assist you with
                your
                medical
                bills will be completely voluntary because participants are not compelled by law to contribute toward
                your
                medical
                bills. Therefore, participation in the ministry or a subscription to any of its documents should not be
                considered
                to be insurance. Regardless of whether you receive any payment for medical expenses or whether this
                ministry
                continues to operate, you are always personally responsible for the payment of your own medical bills.
                <br>
            </p>

        </div>


        <div>

            <b>Arkansas Code 23-60-104.2:</b><br>
            <p class="notices">


                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company and neither its guidelines nor plan of operation is an insurance policy. If anyone
                chooses to assist you with your medical bills, it will be totally voluntary because participants are not
                compelled by law to contribute toward your medical bills. Participation in the organization or a
                subscription to any of its documents should never be considered to be insurance. Regardless of whether
                you receive a payment for medical expenses or if this organization continues to operate, you are always
                personally responsible for the payment of your own medical bills.
            </p>
        </div>


        <div>
            <p style="font-weight: bold; color: #6551a0; margin-bottom: 0px;">
                <b>
                    Florida:
                </b>
            </p>
            <p style="font-weight: bold; color: #6551a0; margin-top: 0px; font-size: 13px; margin-bottom: 0px;"> Statute
                624.1265 </p>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and neither its guidelines nor its plan of operation is an
                insurance policy. Membership is not offered through an insurance company,
                and the organization is not subject to the regulatory requirements or consumer
                protections of the Florida Insurance Code. Whether anyone chooses to assist you
                with your medical bills will be totally voluntary because no other participant
                is compelled by law to contribute toward your medical bills. As such, participation
                in the organization or a subscription to any of its documents should never be
                considered to be insurance. Regardless of whether you receive any payments for
                medical expenses or whether this organization continues to operate, you are
                always personally responsible for the payment of your own medical bills.
            </p>

        </div>

        <div>
            <b>Georgia Statute 33-1-20:</b>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor plan of operation is an insurance policy. Whether anyone chooses to assist you with your
                medical
                bills will be totally voluntary because no other participant will be compelled by law to contribute
                toward
                your
                medical bills. As such, participation in the organization or a subscription to any of its documents
                should
                never
                be
                considered to be insurance. Regardless of whether you receive any payment for medical expenses or
                whether
                this
                organization continues to operate, you are always personally responsible for the payment of your own
                medical
                bills.
            </p>
        </div>


        <div>
            <b>Idaho Statute 41-121:</b><br>
            <p class="notices">
                <strong> Notice:</strong> The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor plan of operation is an insurance policy.
                Whether anyone chooses to assist you with your medical bills will be totally voluntary because no other
                participant
                will be compelled by law to contribute toward your medical bills. As such, participation in the
                organization
                or
                a
                subscription to any of its documents should never be considered to be insurance. Regardless of whether
                you
                receive
                any payment for medical expenses or whether this organization continues to operate, you are always
                personally
                responsible for the payment of your own medical bills.
            </p>
        </div>


        <div>
            <b>Illinois Statute 215-5/4-Class 1-b:</b><br>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor plan of operation constitute or create an insurance policy. Any assistance you receive
                with
                your
                medical bills will be totally voluntary. As such, participation in the organization or a subscription to
                any
                of
                its
                documents should never be considered to be insurance. Whether or not you receive any payments for
                medical
                expenses
                and whether or not this organization continues to operate, you are always personally responsible for the
                payment
                of
                your own medical bills.
            </p>
        </div>

        <div>
            <b>Indiana Code 27-1-2.1:</b><br>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor its plan of operation is an insurance policy. Any assistance you receive with your
                medical
                bills
                will
                be totally voluntary. Neither the organization nor any other participant can be compelled by law to
                contribute
                toward your medical bills. As such, participation in the organization or a subscription to any of its
                documents
                should never be considered to be insurance. Whether or not you receive any payments for medical expenses
                and
                whether
                or not this organization continues to operate, you are always personally responsible for the payment of
                your
                own
                medical bills.
            </p>
        </div>


        <div>
            <b>Kentucky Revised Statute 304.1-120 (7):</b>
            <p class="notices">
                <strong> Notice:</strong>Under Kentucky law, the religious organization facilitating the sharing of
                medical expenses is
                not
                an
                insurance company, and its guidelines, plan of operation, or any other document of the religious
                organization do
                not
                constitute or create an insurance policy. Participation in the religious organization or a subscription
                to
                any
                of
                its documents shall not be considered insurance. Any assistance you receive with your medical bills will
                be
                totally
                voluntary. Neither the organization nor any participant shall be compelled by law to contribute toward
                your
                medical
                bills. Whether or not you receive any payments for medical expenses, and whether or not this
                organization
                continues
                to operate, you shall be personally responsible for the payment of your medical bills.
            </p>
        </div>


        <div>
            <b>Louisiana Revised Statute Title 22-318,319:</b>
            <p class="notices">
                <strong> Notice:</strong>The ministry facilitating the sharing of medical expenses is not an insurance
                company. Neither
                the
                guidelines nor the plan of operation of the ministry constitutes an insurance policy. Financial
                assistance
                for
                the
                payment of medical expenses is strictly voluntary. Participation in the ministry or a subscription to
                any
                publication issued by the ministry shall not be considered as enrollment in any health insurance plan or
                as
                a
                waiver
                of your responsibility to pay your medical expenses.
            </p>
        </div>


        <div>
            <b>Maine Revised Statute Title 24-A, §704, sub-§3:</b>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company and
                neither
                its
                guidelines nor plan of operation is an insurance policy. Whether anyone chooses to assist you with your
                medical
                bills will be totally voluntary because no other participant will be compelled by law to contribute
                toward
                your
                medical bills. Participation in the organization or a subscription to any of its documents should never
                be
                considered to be insurance. Regardless of whether you receive payment for medical expenses or whether
                this
                organization continues to operate, you are always personally responsible for the payment of your own
                medical
                bills.
            </p>
        </div>

        <div>
            <b>Maryland Article 48, Section 1-202(4):</b>
            <p class="notices">
                <strong> Notice:</strong> This publication is not issued by an insurance company nor is it offered
                through an insurance
                company.
                It
                does not guarantee or promise that your medical bills will be published or assigned to others for
                payment.
                No
                other
                subscriber will be compelled to contribute toward the cost of your medical bills. Therefore, this
                publication
                should
                never be considered a substitute for an insurance policy. This activity is not regulated by the State
                Insurance
                Administration, and your liabilities are not covered by the Life and Health Guaranty Fund. Whether or
                not
                you
                receive any payments for medical expenses and whether or not this entity continues to operate, you are
                always
                liable
                for any unpaid bills.
            </p>
        </div>


        <div>

            <p style="font-weight: bold; color: #6551a0; margin-bottom: 0px;">
                <b> Michigan:</b>
            </p>
            <p style="font-weight: bold; color: #6551a0; margin-top: 0px; font-size: 13px; margin-bottom: 0px;"> Section
                550.1867 </p>
            <p class="notices">
                <strong> Notice:</strong>
                Fellowship HealthShare is not an insurance company and the financial assistance provided through the
                ministry is not insurance and is not provided through an insurance company. Whether any participant in
                the ministry chooses to assist another participant who has financial or medical needs is totally
                voluntary. A participant will not be compelled by law to contribute toward the financial or medical
                needs of another participant. This document is not a contract of insurance or a promise to pay for the
                financial or medical needs of a participant by the ministry. A participant who receives assistance from
                the ministry for his or her financial or medical needs remains personally responsible for the payment of
                all of his or her medical bills and other obligations incurred in meeting his or her financial or
                medical needs.
            </p>
        </div>



        <div>
            <b>Mississippi Title 83-77-1:</b>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor plan of operation is an insurance policy. Whether anyone chooses to assist you with your
                medical
                bills will be totally voluntary because no other participant will be compelled by law to contribute
                toward
                your
                medical bills. As such, participation in the organization or a subscription to any of its documents
                should
                never
                be
                considered to be insurance. Regardless of whether you receive any payment of medical expenses or whether
                this
                organization continues to operate, you are always personally responsible for the payment of your own
                medical
                bills.
            </p>
        </div>

        <div>
            <b>Missouri Section 376.1750:</b>
            <p class="notices">
                <strong> Notice:</strong>This publication is not an insurance company nor is it offered through an
                insurance company.
                Whether
                anyone
                chooses to assist you with your medical bills will be totally voluntary, as no other subscriber or
                member
                will
                be
                compelled to contribute toward your medical bills. As such, this publication should never be considered
                to
                be
                insurance.
                Whether you receive any payments for medical expenses and whether or not this publication continues to
                operate,
                you
                are always personally responsible for the payment of your own medical bills.
            </p>
        </div>

        <div>
            <b>Nebraska Revised Statute Chapter 44-311:</b>
            <p class="notices">
                IMPORTANT NOTICE. This organization is not an insurance company, and its product should never be
                considered
                insurance. If you join this organization instead of purchasing health insurance, you will be considered
                uninsured.
                By the terms of this agreement, whether anyone chooses to assist you with your medical bills as a
                participant of
                this organization will be totally voluntary, and neither the organization nor any participant can be
                compelled
                by
                law to contribute toward your medical bills. Regardless of whether you receive payment for medical
                expenses
                or
                whether this organization continues to operate, you are always personally responsible for the payment of
                your
                own
                medical bills. This organization is not regulated by the Nebraska Department of Insurance. You should
                review
                this
                organization’s guidelines carefully to be sure you understand any limitations that may affect your
                personal
                medical
                and financial needs.
            </p>
        </div>


        <div>
            <b>New Hampshire Section 126-V:1:</b>
            <p class="notices">
                <strong> IMPORTANT NOTICE:</strong> This organization is not an insurance company, and its product
                should never be
                considered
                insurance. If you join this organization instead of purchasing health insurance, you will be considered
                uninsured.
                By the terms of this agreement, whether anyone chooses to assist you with your medical bills as a
                participant of
                this organization will be totally voluntary, and neither the organization nor any participant can be
                compelled
                by
                law to contribute toward your medical bills. Regardless of whether you receive payment for medical
                expenses
                or
                whether this organization continues to operate, you are always personally responsible for the payment of
                your
                own
                medical bills. This organization is not regulated by the New Hampshire Insurance Department. You should
                review
                this
                organization’s guidelines carefully to be sure you understand any limitations that may affect your
                personal
                medical
                and financial needs.
            </p>
        </div>

        <div>
            <b>North Carolina Statute 58-49-12:</b>
            <p class="notices">
                <strong> Notice:</strong> The organization facilitating the sharing of medical expenses is not an
                insurance company and
                neither
                its
                guidelines nor its plan of operation is an insurance policy. Whether anyone chooses to assist you with
                your
                medical
                bills will be voluntary. No other participant will be compelled by law to contribute toward your medical
                bills.
                As
                such, participation in the organization or a subscription to any of its documents should never be
                considered
                to
                be
                insurance. Regardless of whether you receive any payment for medical expenses or whether this
                organization
                continues
                to operate, you are always personally liable for the payment of your own medical bills.
            </p>
        </div>


        <div>
            <p style="font-weight: bold; color: #6551a0;">
                <b>
                    Oklahoma:
                </b>
            </p>
            <p class="notices">
                This is not an insurance policy. It is a voluntary program that is neither approved,
                endorsed or regulated by the Oklahoma Department of Insurance and the program is not
                guaranteed under the Oklahoma Life and Health Guaranty Association.
            </p>
        </div>





        <div>
            <b>Pennsylvania 40 Penn. Statute Section 23(b):</b>
            <p class="notices">
                <strong> Notice:</strong>This publication is not an insurance company nor is it offered through an
                insurance company.
                This
                publication does not guarantee or promise that your medical bills will be published or assigned to
                others
                for
                payment. Whether anyone chooses to pay your medical bills will be totally voluntary. As such, this
                publication
                should never be considered a substitute for insurance. Whether you receive any payments for medical
                expenses
                and
                whether or not this publication continues to operate, you are always liable for any unpaid bills.

            </p>
        </div>


        <div>
            <b>South Dakota Statute Title 58-1-3.3:</b>
            <p class="notices">
                <strong> Notice:</strong>The organization facilitating the sharing of medical expenses is not an
                insurance company, and
                neither
                its
                guidelines nor plan of operation is an insurance policy. Whether anyone chooses to assist you with your
                medical
                bills will be totally voluntary because no other participant will be compelled by law to contribute
                toward
                your
                medical bills. As such, participation in the organization or a subscription to any of its documents
                should
                never
                be
                considered to be insurance. Regardless of whether you receive any payments for medical expenses or
                whether
                this
                organization continues to operate, you are always personally responsible for the payment of your own
                medical
                bills.
            </p>
        </div>

        <div>
            <b>Texas Code Title 8, K, 1681.001:</b>
            <p class="notices">
                <strong> Notice:</strong>This health care sharing ministry facilitates the sharing of medical expenses
                and is not an
                insurance
                company, and neither its guidelines nor its plan of operation is an insurance policy. Whether anyone
                chooses
                to
                assist you with your medical bills will be totally voluntary because no other participant will be
                compelled
                by
                law
                to contribute toward your medical bills. As such, participation in the ministry or a subscription to any
                of
                its
                documents should never be considered to be insurance. Regardless of whether you receive any payment for
                medical
                expenses or whether this ministry continues to operate, you are always personally responsible for the
                payment of
                your own medical bills. Complaints concerning this health care sharing ministry may be reported to the
                office of
                the
                Texas attorney general.
            </p>
        </div>


        <div>
            <p style="font-weight: bold; color: #6551a0;">
                <b> Utah: </b>
            </p>

            <p class="notices">
                This is not an insurance policy. It is a voluntary program that is neither approved,
                endorsed or regulated by the Utah Department of Insurance and the program is not
                guaranteed under the Utah Life and Health Guaranty Association.
            </p>

        </div>


        <div>
            <b>Virginia Code 38.2-6300-6301:</b>
            <p class="notices">
                <strong> Notice:</strong>This publication is not insurance, and is not offered through an insurance
                company. Whether
                anyone
                chooses
                to assist you with your medical bills will be totally voluntary, as no other member will be compelled by
                law
                to
                contribute toward your medical bills. As such, this publication should never be considered to be
                insurance.
                Whether
                you receive any payments for medical expenses and whether or not this publication continues to operate,
                you
                are
                always personally responsible for the payment of your own medical bills.
            </p>
        </div>



        <div>
            <p style="font-weight: bold; color: #6551a0;">
                <b> Washington:</b>
            </p>
            <p class="notices">
                This is not an insurance policy. It is a voluntary program that is neither approved,
                endorsed or regulated by the Washington Department of Insurance and the program is not
                guaranteed under the Washington Life and Health Guaranty Association.
            </p>
        </div>


        <div>
            <b>Wisconsin Statute 600.01 (1) (b) (9):</b>
            <p class="notices">
                ATTENTION: This publication is not issued by an insurance company, nor is it offered through an
                insurance
                company.
                This publication does not guarantee or promise that your medical bills will be published or assigned to
                others
                for
                payment. Whether anyone chooses to pay your medical bills is entirely voluntary. This publication should
                never
                be
                considered a substitute for an insurance policy. Whether or not you receive any payments for medical
                expenses,
                and
                whether or not this publication continues to operate, you are responsible for the payment of your own
                medical
                bills.
            </p>
        </div>


        <div>
            <p style="font-weight: bold; color:#6551a0 ; margin-bottom: 0px;">
                <b> Wyoming: </b>
            </p>
            <p style="font-weight: bold;  color: #6551a0; margin-top: 0px; font-size: 13px; margin-bottom: 0px;">
                Statute 26.1.104 (a)(v)(c): </p>
            <p class="notices">
                <strong> Notice:</strong> The organization facilitating the sharing of medical expenses is not an
                insurance company,
                and neither its guidelines nor plan of operation is an insurance policy. Any assistance with your
                medical bills is completely voluntary. No other participant is compelled by law or otherwise to
                contribute toward your medical bills. Participation in the organization or a subscription to any
                of its documents shall not be considered to be health insurance and is not subject to the
                regulatory requirements or consumer protections of the Wyoming insurance code. You are
                personally responsible for payment of your medical bills regardless of any financial sharing
                you may receive from the organization for medical expenses. You are also responsible for
                payment of your medical bills if the organization ceases to exist or ceases to facilitate
                the sharing of medical expenses.
            </p>
        </div>
    </div>
    <br>
    <br>
</section>