import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharingService } from './services/sharing.service';
import { ConfigService } from './services/config.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Fellowship Healthshare';
  lastUrlSegment: any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  constructor(private config: ConfigService, public router: Router, private http: HttpClient, private service: SharingService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.dynamicVariables();
    localStorage.removeItem('planupdate');
    localStorage.removeItem('summaryPlanupdate');


    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  decodeCompanySettings(res) {
    res = JSON.parse(atob(atob(res)))
    res.api_Key = atob(res.api_Key)
    res.client_secret = atob(res.client_secret)
    return res;
  }

  // *********based on url get company settings *******************//
  dynamicVariables() {
    var splitUrl = window.location.host.split('.');
    var reverArray = splitUrl.reverse();
    this.lastUrlSegment = reverArray[1];
    var url = window.location.origin + '/vasquez-api/api/GetCompanySettings/' + this.lastUrlSegment;

    //************used for local testing*********//
    //  this.lastUrlSegment = "fellowshiphealthshare";
    //   var url = "https://sandbox.fellowshiphealthshare.org/vasquez-api/api/GetCompanySettings/fellowshiphealthshare"

    this.http.get(url).subscribe((obj: any) => {
      obj.data = this.decodeCompanySettings(obj.data)
      obj.data.companyUrl = this.lastUrlSegment;
      this.service.getClientData(obj.data);
      //************Browser favicon*********//
      if (this.lastUrlSegment == 'fellowshiphealthshare') {
        // this.titleService.setTitle(obj.data.company_name);
        this.titleService.setTitle('Fellowship Healthshare | Healthcare | Healthshare');
      } else if (this.lastUrlSegment == 'eternityhealthshare') {
        this.titleService.setTitle(obj.data.company_name);
        this.favIcon.href = 'assets/images/eternityFav.png';
      }
    }, err => {
      console.log(err);
    })
  }

  ngOnDestroy() {

  }
}
