import { FormGroup } from "@angular/forms";

export function MustMatch(ControlName: string, matchingControlName : string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[ControlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if(matchingControl.errors && !matchingControl.errors.mustMatch){
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation false
        if(control.value !== matchingControl.value){
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    }
}