import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharingService } from '../services/sharing.service';
import { TestService } from '../services/behaviourService';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resetmemberpassword',
  templateUrl: './resetmemberpassword.component.html',
  styleUrls: ['./resetmemberpassword.component.scss'],
  providers: [DatePipe]
})
export class ResetmemberpasswordComponent implements OnInit {
  newForm: FormGroup;
  submitAttempt: boolean = false;
  forgotPasswordForm: FormGroup;
  validPassword: boolean;
  reEnterNewPassword: any;
  enterNewPassword: any;
  passwordMismatch = false;
  token: any;
  email: any;
  resetFormValue: any;
  successMessage: any;
  errorMessage: any;
  success: boolean = false;
  failure: boolean = false;
  minimumDate: Date;
  invalidDates: Date[];
  invalidDates1: Date[];
  doberror: any;
  passwordToggle = "Show";
  passwordToggle1 = "Show";
  companyInfo: any;

  constructor(
    private objectService: TestService,
    private formBuilder: FormBuilder,
    private service: SharingService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
    this.service.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.GetDecryptData()
      }
    })
  }

  ngOnInit(): void {
    this.minimumDate = new Date();
    let today = new Date();
    let invalidDate1 = new Date();
    this.invalidDates1 = [today, invalidDate1];
    this.objectService.pushheaderCondition("showheader");
    this.GetDecryptData()
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],//, Validators.pattern(this.emailPattern)
      newPassword: ['', Validators.required],
      reEnterNewPassword: ['', Validators.required],
      dob: ['', Validators.required,],
      // ssn: ['', Validators.required,],
      user_id: ['']
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onKeyUpPassword(input) {
    this.enterNewPassword = input;
    const passwordCheckRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&^]{6,}$/;
    const validPassword = passwordCheckRegex.test(input);
    this.validPassword = !validPassword;
    if (!this.passwordMismatch) {
      this.passwordMismatch = this.enterNewPassword !== this.reEnterNewPassword;
    }
    if (this.passwordMismatch) {
      this.passwordMismatch = this.enterNewPassword !== this.reEnterNewPassword;
    }
  }
  onKeyUpReEnterPassword(input) {
    this.reEnterNewPassword = input;
    this.passwordMismatch = this.enterNewPassword !== input;
  }
  onChangePassword(value) {
    this.submitAttempt = true;
    if (value.newPassword == value.reEnterNewPassword) {
      if (this.forgotPasswordForm.valid) {
        let body = {
          user_id: value.user_id,
          password: value.newPassword,
          dob: this.datePipe.transform(value.dob, "yyyy-MM-dd"),
        }
        var url = 'ResetMemberPassword';
        this.service.putData(url, body).subscribe((Object: any) => {
          if (Object != null) {
            this.toastr.success(Object.message);
            this.forgotPasswordForm.reset();
            this.submitAttempt = false
          }
        }, err => {
          if (err.error.dob) {
            this.toastr.warning(err.error.dob)
          }
          // if (err.error.ssn4) {
          //   this.toastr.warning(err.error.ssn4)
          // }
        })
      }
    }
  }
  onShowHide(value, toggle) {
    if (toggle == 'Show') {
      value.type = "text";
      this.passwordToggle = "Hide";
    }
    if (toggle == 'Hide') {
      value.type = "password";
      this.passwordToggle = "Show";
    }
  }
  onHide(password) {
    password.type = "password";
  }
  onShow(password) {
    password.type = "text";
  }
  onShowHide1(value, toggle1) {
    if (toggle1 == 'Show') {
      value.type = "text";
      this.passwordToggle1 = "Hide";
    }
    if (toggle1 == 'Hide') {
      value.type = "password";
      this.passwordToggle1 = "Show";
    }
  }
  onHide1(password) {
    password.type = "password";
  }
  onShow1(password) {
    password.type = "text";
  }
  GetDecryptData() {
    var A2CUrl = window.location.href;
    const lastUrlSegment = A2CUrl.split('?')[0].split('/').pop();
    var url = "GetDecryptMemberId/" + lastUrlSegment
    this.service.getData(url).subscribe((Object: any) => {
      this.forgotPasswordForm.controls.email.setValue(Object.data.email);
      this.forgotPasswordForm.controls.user_id.setValue(Object.data.user_id);
    })
  }
}
