import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnvironmentVariableReducer } from './reducers/getEnvironmentVariables.reducer';
import { StateReducer } from './reducers/getStates.reducer';
import { Effects } from './effects/effects';

@NgModule({
  
  imports: [
      StoreModule.forRoot({
        state: StateReducer,
        environmentVar: EnvironmentVariableReducer
      },{}),
      EffectsModule.forRoot([
        Effects
      ])
  ],
})
export class NgrxStoreModule { }
