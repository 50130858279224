import { EnvironmentActionType } from "../actions/environment-variables.actions";
import { EndpointActionTypes } from "../state/state";

export interface EnvironmentVariableReducerState {
    isLoading: boolean;
    isLoaded: boolean;
    error: string | any;
    env_variables: any;
}

const initialState: EnvironmentVariableReducerState = {
    isLoading: false,
    isLoaded: false,
    error: '',
    env_variables: []
}

export function EnvironmentVariableReducer(state: EnvironmentVariableReducerState = initialState, action: EnvironmentActionType) {
    switch (action.type) {
        case EndpointActionTypes.SET_ENVIRONMENT_VARIABLES:
            {
                return {
                    ...state,
                    isLoading: false
                }
            }
        case EndpointActionTypes.GET_ENVIRONMENT_VARIABLES:
            {
                return {
                    ...state,
                    isLoading: true,
                    isLoaded: false
                }
            }
        case EndpointActionTypes.GET_ENVIRONMENT_VARIABLES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                env_variables: action.payload
            }
        }
        case EndpointActionTypes.GET_ENVIRONMENT_VARIABLES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.payload
            }
        }
        default:
            return state;
    }
}