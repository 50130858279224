import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TestService } from '../../services/behaviourService';
import { ConfigService } from '../../services/config.service';
import { SharingService } from '../../services/sharing.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-summary-details',
  templateUrl: './summary-details.component.html',
  styleUrls: ['./summary-details.component.scss']
})
export class SummaryDetailsComponent implements OnInit {

  applicant: any;
  cartProducts: any;
  memberdata: any;
  paymentData: any;
  orderDetails: any;
  loader: boolean = false;
  cardType: boolean = false;

  errMsg: any;
  memer: any;
  order_id: any;
  expdate: any;
  year: any;
  month: any;
  card: any;
  cardData: any;
  account_type: string;
  signature: any;
  date = new Date();
  applicant_type: any;
  errorMessage: any;
  data: any;
  displayplane: boolean = true;
  checkedSignature: boolean = false;
  applicantname: string;
  agentInfo: any;
  companyId: any;

  cartFee: any;
  cartData: any;
  memberDiscount: any;
  bmiinfo: any;
  additionalMember: any;

  signatureverify: boolean;
  showCheckboxError = "";
  showSignatureVerifyError = "";
  showSignatureVerifyError1 = "";
  companyInfo: any;
  companyName: any;
  oneTimeFee: any;
  monthlyContribution: any;
  monthlyContributionStartDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private objectService: TestService,
    private configService: ConfigService,
    private services: SharingService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.agentInfo = JSON.parse(sessionStorage.getItem('agentinfo'));
    this.services.clientData.subscribe((data) => {
      if (data != '') {
        this.companyInfo = data;
        this.companyId = this.companyInfo.company_id;
        this.companyName = this.companyInfo.company_name
      }
    })
    this.objectService.plansSObject.subscribe(result => {
      this.applicant = result;
      this.cartFee = this.applicant.cart_fee;
      this.cartData = this.applicant.cart_data;
      this.memberDiscount = this.applicant.agent_cart_discount;
      this.bmiinfo = this.applicant.cart_bmi;
      this.additionalMember = this.applicant.additional_member_fee;
      this.oneTimeFee = this.applicant.a2c_total_one_time_fee;
      var data = result;
      this.data = data.cart_products;
      this.displayplane = false
    });
    var applicantData= JSON.parse(localStorage.getItem('appCart'));
    this.cartFee = applicantData.cart_fee;
    this.cartData = applicantData.cart_data;
    this.memberDiscount = applicantData.agent_cart_discount;
    this.oneTimeFee = applicantData.a2c_total_one_time_fee;
    this.cartProducts = applicantData.cart_products;
    this.monthlyContribution = applicantData.monthly_contribution;
    this.monthlyContributionStartDate = applicantData.member_applications_data[0].monthly_contribution_start_date;
    this.objectService.paymentObject.subscribe(result => {
      this.applicant = result;
      this.applicant_type = this.applicant.member_personal_data[0].firstname + "  " + this.applicant.member_personal_data[0].lastname
      this.displayplane = true
      this.orderDetails = this.applicant.order_details;
      this.memberdata = this.applicant.member_personal_data;
      if (this.orderDetails !== undefined) {
        this.order_id = this.orderDetails[0].order_id;
        this.getSummeryDetails(this.order_id);
      }
    })
  }
  ngOnInit(): void {
  }
  getSummeryDetails(order_id) {
    var url = 'GetSharingOrder/' + this.companyId + '/' + order_id;
    this.services.getData(url).subscribe((Object: any) => {
      if (Object.status == 'success') {
        this.memberdata = Object.member_personal_data;
        this.applicantname = this.memberdata[0].firstname + " " + this.memberdata[0].lastname
        for (let i = 0; i < this.memberdata.length; i++) {
          var ssn = "***" + '-' + '**' + '-';
          if (this.memberdata[i].ssn4) {
            this.memberdata[i].ssn4 = ssn + this.memberdata[i].ssn4;
          }
          if (this.memberdata[i].phone) {
            this.memberdata[i].phone = this.memberdata[i].phone.substring(0, 3) + '-' + this.memberdata[i].phone.substring(3, 6) + '-' + this.memberdata[i].phone.substring(6, 10);
          }
          var height = this.memberdata[i].height.toString();
          var n = height.toString().includes(".");
          if (n == false) {
            var str2 = ".0";
            var height = height.toString().concat(str2);
          }
          const Height = height.toString();
          var inch = height.substring(Height.lastIndexOf('.') + 1);
          var ft = height.substring(0, Height.lastIndexOf('.'));
          this.memberdata[i].height = ft + "'" + " " + inch + "''"
          // this.memberdata[i].height = ft + 'ft.' + inch + 'inches';
        }
        this.paymentData = Object.paymentdata;
        if (this.paymentData[0].payment_type == "CARD") {
          this.cardType = false;
          this.cardData = this.paymentData[0].card;
        }
        if (this.paymentData[0].payment_type == "BANK") {
          this.cardType = true;
          if (this.paymentData[0].account_type == "PS") {
            this.account_type = "Savings";
          }
          else {
            this.account_type = "Checking";
          }
        }
      }
    })
  }
  onfocusoutSignature() {
    this.signature = (<HTMLInputElement>document.getElementById("signature")).value;
    console.log(this.signature);
    console.log(this.applicantname);
    if (this.applicantname.toUpperCase() === this.signature.toUpperCase()) {
      this.showSignatureVerifyError = '';
      this.showSignatureVerifyError1 = '';
    }
  }
  finalSubmit() {
    if (!this.checkedSignature) {
      this.showCheckboxError = "Please check signature checkbox";
      setTimeout(() => {
        this.showCheckboxError = "";
      }, 4000);
    }
    if (this.checkedSignature && !this.signatureverify) {
      this.showSignatureVerifyError = 'Your Signature Must Match ';
      this.showSignatureVerifyError1 = 'Your First Name and Lastname is:' + " " + " " + this.applicantname;
      setTimeout(() => {
        this.showSignatureVerifyError = "";
      }, 9000);
    }
    if (this.checkedSignature && this.signature != undefined) {
      if (this.applicantname.toUpperCase() === this.signature.toUpperCase()) {
        this.signatureverify = true;
      }
      else {
        this.signatureverify = false;
      }
    }
    if (this.checkedSignature && this.signatureverify) {
      this.showSignatureVerifyError = "";
      var orderId = this.order_id;
      let body = {
        order_id: orderId,
        acknowledge_by: this.signature,
        company_id: this.companyId,
        company_name: this.companyName
      };
      this.loader = true;
      var url = 'ProcessOrderSharing';
      this.services.postData(url, body).subscribe((Object: any) => {
        this.services.pushOrderDetails(Object)
        if (Object.status == 'success') {
          this.loader = false;
          this.router.navigate(['thankyou']);
          var plansAndProducts = "";
          this.objectService.changeUsername(plansAndProducts);
        }
        if (Object.status == 'failure') {
          this.loader = false;
          this.errMsg = Object.order_details[0].message;
          setTimeout(() => {
            this.errMsg = ""
          }, 3000);
        }
      }, err => {
        this.loader = false;
        this.errorMessage = err.error.acknowledge_by[0];
        setTimeout(() => {
          this.errorMessage = ""
        }, 3000);
      })
    }
  }
  onCheckbox(e) {
    if (e.target.checked == true) {
      this.checkedSignature = true;
      this.showCheckboxError = "";
    }
    else {
      this.checkedSignature = false;
    }
  }
  changeToDemography() {
    var url = 'GetMemberDependents/' + this.companyId + '/' + this.memberdata[0].member_id + '/' + this.applicant.cart_data[0].id;
    this.services.getData(url).subscribe((Object: any) => {
      this.objectService.addCartPlan(Object);
      this.router.navigate(['A2C/demographic/A2C']);
    }, err => {
      console.log(err)
    })
  }
  changeToPaymeny() {
    var url = 'GetPayment/' + this.companyId + '/' + this.memberdata[0].member_id;
    this.services.getData(url).subscribe((Object: any) => {
      this.objectService.demographyH(Object);
      this.router.navigate(['A2C/payment']);
    }, err => {
      console.log(err)
    })
  }
}
