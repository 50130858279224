import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/featuresModule/validators/must-match.validator';

@Component({
  selector: 'bank-payment',
  templateUrl: './bank-payment.component.html',
  styleUrls: ['./bank-payment.component.scss']
})
export class BankPaymentComponent implements OnInit {
  routingGroup: FormGroup;
  accountGroup: FormGroup;
  constructor(private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.createForm();
  }

  get routingNumber() { return this.routingGroup.controls; }
  get accountNumber() { return this.accountGroup.controls; }

  createForm() {
    this.routingGroup = this.formBuilder.group({
      routing: ['', [Validators.required]],
      confirmRouting: ['', [Validators.required]],
    }, {
      validator: MustMatch('routing', 'confirmRouting')
    });
    this.accountGroup = this.formBuilder.group({
      account: ['', [Validators.required,Validators.minLength(4)]],
      confirmAccount: ['', [Validators.required]],
    }, {
      validator: MustMatch('account', 'confirmAccount')
    });
  }

}
