<div class="container">
    <br>
    <div class="row">
        <div class="col-lg-4 ">
            <img style="border: 1px solid #eff0f4;width: 100%; padding: 4px;border-radius: 14px;"
                src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ0rQL752TBJSYe_rCtra_XMPRn4JXpur7AEqvKWzc9xdqp_1F4">
        </div>
        <div class="col-lg-8">
            <p></p>
            <p></p>
        </div>
        <div class="col-lg-12" style="text-align: right;">
            <button type="button" class="btn btn-primary" >Become a member</button>
        </div>
        <br>
        <br>
        <br>
    </div>
</div>