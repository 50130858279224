<nav class="navbar-default sticky-top fs-header shadow-sm">

  <label for="res-menu" class="mobilemenu" *ngIf="loadedData=='showheader'">
    <i class="fa fa-bars" id="sign-one" (click)="menu()" *ngIf="status"></i>
    <i class="fa fa-times" id="sign-two" (click)="submenu()" *ngIf="!status"></i>
  </label>
  <img src="./assets/logo.png" alt="Fellowship" routerLink="/home" width="190px" height="auto" *ngIf="companyContent">
  <img src="./assets/eternity.jpg" alt="eternity" routerLink="/home" class="etrnityLogo" *ngIf="!companyContent">
  <span *ngIf="companyContent">
    <ul class="navblock" [ngClass]="status ?'':'statuschange'" *ngIf="loadedData=='showheader'">
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/home">HOME</a></li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/what-we-do">WHAT WE DO</a>
      </li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/how-it-works">HOW IT
          WORKS</a>
      </li>
      <!-- <li><a class="nav-link" (click)="hide()"  routerLinkActive="active" routerLink="/who-we-are">WHO WE ARE</a></li> -->
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/membership">MEMBERSHIP</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
          aria-expanded="false">
          RESOURCES
        </a>
        <div class="dropdown-menu" (click)="hide()" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" (click)="hide()" routerLinkActive="active" routerLink="/program-brochure">Program
            Brochure</a>
          <!-- <a class="dropdown-item" (click)="hide()"  routerLinkActive="active" routerLink="/membership-guideline">Membership Guidelines  </a> -->
          <a class="dropdown-item" (click)="hide()" routerLinkActive="active" routerLink="/form-document">Forms and
            Documents</a>
          <a class="dropdown-item" (click)="hide()" routerLinkActive="active" routerLink="/blog">Blog</a>




        </div>
      </li>
      <!-- <li><a class="nav-link" (click)="hide()"  routerLinkActive="active" routerLink="/resources">RESOURCES</a></li> -->
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/get-in-touch">CONTACT US</a>
      </li>
      <li>
        <button routerLink="/dynamicplan" (click)="hide()" class="btn btn-sm btn-primary member">
          <i class="bi bi-person-plus mr-2"></i> Become A Member
        </button>
      </li>
      <li>
        <a href="{{memberLogin}}" target="_blank" class="btn btn-sm btn-primary member">
          <i class="bi bi-person-badge mr-2"></i>
          Member Login
        </a>
      </li>
    </ul>
  </span>
  <span *ngIf="!companyContent">
    <ul class="navblock" [ngClass]="status ?'':'statuschange'" *ngIf="loadedData=='showheader'">
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active" routerLink="/home">HOME</a></li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active">WHAT WE DO</a></li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active">HOW IT WORKS</a> </li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active">MEMBERSHIP</a></li>
      <li><a class="nav-link" (click)="hide()" routerLinkActive="active">CONTACT US</a>
      </li>
      <li>
        <button routerLink="/dynamicplan" (click)="hide()" class="btn btn-sm btn-primary member mx-2">
          Become A member
        </button>
      </li>
      <li>
        <a class="btn btn-sm btn-primary " href="{{memberLogin}}" target="_blank">
          <i class="fa fa-user-circle "></i>
          Member Login
        </a>
      </li>
    </ul>
  </span>

</nav>
