import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[customize]'
})
export class CustomizeInputDirective {
  @Input() numbersOnly: boolean;
  @Input() preventCopy: boolean;
  @Input() preventPaste: boolean;
  @Input() preventCut: boolean;

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value && this.numbersOnly) {
      event.stopPropagation();
    }
  }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (this.preventPaste) {
      e.preventDefault();
    }
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (this.preventCopy) {
      e.preventDefault();
    }
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (this.preventCut) {
      e.preventDefault();
    }
  }

}