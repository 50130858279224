<section>
  <div class="container">
    <h2 class=all>Program Brochure</h2>
    <div class="">
      <div class="col-sm-12">
      </div>
      <div class="card">
        <div class="card-body" id="heading7Two">
          <div class="row">
            <div class="col-md-8">
              <h5 class="mb-0">
                Basic Program Brochure </h5>
            </div>
            <div class="col-md-4 d-flex justify-content-between">
              <a class="btn btn-secondary" download href="assets/document/fellowship-basic-program-feb2022.pdf"><i
                  class="fa fa-download" aria-hidden="true"></i> Download</a>
            
              <a class="btn btn-secondary" target="_blank"
                href="assets/document/fellowship-basic-program-feb2022.pdf"><i class="fas fa-file-pdf"></i> View
                Brochure</a>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body" id="heading7Two">
          <div class="row">
            <div class="col-md-8">
              <h5 class="mb-0">
                Essential Program Brochure </h5>
            </div>
            <div class="col-md-4 d-flex justify-content-between">
              <a class="btn btn-secondary" download href="assets/document/fellowship-essential-program-feb2022.pdf"><i
                  class="fa fa-download" aria-hidden="true"></i> Download</a>
           
              <a class="btn btn-secondary" target="_blank"
                href="assets/document/fellowship-essential-program-feb2022.pdf"><i class="fas fa-file-pdf"></i> View
                Brochure</a>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body" id="heading7Two">
          <div class="row">
            <div class="col-md-8">
              <h5 class="mb-0">
                Choice Program Brochure </h5>
            </div>
            <div class="col-md-4 d-flex justify-content-between">
              <a class="btn btn-secondary" download href="assets/document/fellowship-choice-program-feb2022.pdf"><i
                  class="fa fa-download" aria-hidden="true"></i> Download</a>
            
              <a class="btn btn-secondary" target="_blank"
                href="assets/document/fellowship-choice-program-feb2022.pdf"><i class="fas fa-file-pdf"></i> View
                Brochure</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
