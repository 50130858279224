<div *ngIf="orderDetails==undefined">
    <p class="empty_page">Error:404</p>
</div>
<br>
<div class="container">
    <div class="column col-lg-12" id="content" *ngIf="orderDetails !=undefined">
        <div class="confirmation">
            <h4 style="margin: 0;">Thank You For Your Order
                <span class="downloadorder" style="cursor: pointer; 
                    text-decoration: underline;
                    font-size: 20px;  color: #ED1C24;" (click)="exportPdf()"><a>Download your copy</a>
                </span>
            </h4>
        </div>
        <div class="my-invoices" id="orderslist">
            <div class="inner-container clearfix" id="invoice"
                style=" background-color: white !important;padding: 4px;">
                <div class="container-fluid pdf_cs">
                    <div class="row">
                        <div class="col-lg-10">
                            <h2 *ngFor="let item of orderDetails">Hi, {{item.acknowledge_by}}</h2>
                            <p>Receipt for your order for <span>{{plannames}}
                                    Program</span></p>
                        </div>
                        <div class="col-lg-2 imgalign">
                            <img class="successImg" src="./assets/img/success.jpg">
                        </div>
                    </div>
                    <div class="invoice-box">
                        <div class="row">
                            <div class="column  col-md-4 col-sm-12 col-xs-12" style="padding-right: 0;">
                                <div *ngIf="enrolmantType !='G2C'">
                                    <h3>Billed To:</h3>
                                    <ul class="invoice-info" *ngFor="let item of orderDetails">
                                        <li>
                                            {{item.firstname}}
                                            {{item.lastname}}
                                        </li>
                                        <li>
                                            {{item.billing_address1}},
                                            {{item.billing_address2}}
                                        </li>
                                        <li>
                                            {{item.billing_city}},
                                            {{item.billing_state}} ,
                                            {{item.billing_zip}}
                                        </li>
                                        <li>
                                            {{item.email}}
                                        </li>
                                        <li>
                                            {{item.mobile}}
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="enrolmantType =='G2C'">
                                    <div *ngIf="orderDetails?.length > 0">
                                        <h3>Order By:</h3>
                                        <div *ngFor="let item of orderDetails">
                                            <div class="gAddress">
                                                {{item.firstname}} {{item.lastname}}
                                            </div>
                                            <div class="gAddress">
                                                {{item.address1}}, {{item.address2}}
                                            </div>
                                            <div class="gAddress">
                                                {{item.city}}, {{item.state}}, {{item.zip}}
                                            </div>
                                            <div class="gAddress">
                                                {{item.email}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column  col-md-4 col-sm-12 col-xs-12" style="padding-right: 0;">
                                <div *ngIf="groupInfo?.length > 0">
                                    <h3>Billed To:</h3>
                                    <div *ngFor="let item of groupInfo">
                                        <div class="gAddress">
                                            {{item.name}}
                                        </div>
                                        <div class="gAddress">
                                            {{item.mobile}}
                                        </div>
                                        <div class="gAddress">
                                            {{item.email}}
                                        </div>
                                        <div class="gAddress">
                                            {{item.address1}}, {{item.city}}, {{item.state}}, {{item.zip}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="column   col-md-2 col-sm-12 col-xs-12">
                                <h3>Shipped To:</h3>
                                <ul class="invoice-info">
                                    <li>
                                        {{orderDetails[0].firstname}}
                                        {{orderDetails[0].lastname}}
                                    </li>
                                    <li>
                                        {{orderDetails[0].shipping_address1}}
                                        {{orderDetails[0].shipping_address2}}
                                    </li>
                                    <li>
                                        {{orderDetails[0].shipping_city}},
                                        {{orderDetails[0].shipping_state}} ,
                                        {{orderDetails[0].shipping_zip}}
                                    </li>
                                    <li>
                                        {{orderDetails[0].email}}
                                    </li>
                                    <li>
                                        {{orderDetails[0].mobile}}
                                    </li>
                                </ul> 
                            </div>-->
                            <div class="column   col-md-4 col-sm-12 col-xs-12" style="padding: 0;">
                                <div *ngFor="let item of orderDetails">
                                    <div class="row">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            <div class="orderId"> Order ID
                                            </div>
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <span class="rightspace" style="margin-right: 23px;">:</span>
                                            <span>{{item.order_id}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            date & Time
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <span class="rightspace">:</span>
                                            <span>
                                                {{item.created_at | date: 'd-MMM-y, h:mm:ss a'}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="enrolmantType=='G2C'">
                                    <div class="col-md-5 col-xs-5 orderPadding">
                                        Payment Method
                                    </div>
                                    <div class="col-md-7 col-xs-7" style="padding:0;">
                                        <span class="rightspace">:</span>
                                        <span>
                                            Group
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="paymentData !=undefined">
                                    <div class="row">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            <div>Payment Method</div>
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <span class="rightspace">:</span>
                                            <span>
                                                {{this.orderDetails[0].payment_type}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="paymentData[0].payment_type =='CARD'">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            <div>Card Number</div>
                                            <!-- <div>Expiry</div> -->
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <div>
                                                <span class="rightspace">:</span> {{paymentData[0].card}}
                                            </div>
                                            <!-- <div>
                                                <span class="rightspace">:</span> {{month}}/{{year}}
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="this.paymentData[0].payment_type !='CARD'">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            <div>Routing Number</div>
                                            <div>Account Number</div>
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <div>
                                                <span class="rightspace">:</span> {{paymentData[0].routing}}
                                            </div>
                                            <div>
                                                <span class="rightspace">:</span> {{paymentData[0].account}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 col-xs-5 orderPadding">
                                            <div class="orderId"> Confirmation Number
                                            </div>
                                        </div>
                                        <div class="col-md-7 col-xs-7" style="padding:0;">
                                            <span class="rightspace">:</span>
                                            <span>{{orderDetails[0].confirmation_number}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p style="margin-bottom: 2px;" *ngFor="let item of orderDetails"> <span
                                        style="font-size: 19px; margin-right: 2%;"> Terms
                                        &
                                        Conditions</span> <span><span>Accepted By :</span>
                                        {{item.acknowledge_by}} </span> </p>
                            </div>
                        </div>
                        <div class="table-responsive ">
                            <table class="table">
                                <thead style="background-color: #80c5ce;">
                                    <tr>
                                        <th style="width: 49%; border-right: 2px solid white"><strong>Item</strong></th>
                                        <th style="width: 50%; text-align: end;"><strong>Price</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of cartProducts">
                                        <td>{{item.name}} Program
                                            <div *ngFor="let member of item.family_info" class="applicantName">
                                                {{member.firstname | titlecase}}
                                            </div>
                                        </td>
                                        <td style="text-align: end;"> {{item.total_price | currency:'USD'}}
                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of cart_addon_products">
                                        <td>{{item.Name}} </td>
                                        <td style="text-align: end;"> {{item.total_amount | currency:'USD'}}</td>
                                    </tr>
                                    <tr *ngFor="let item of cartFee">
                                        <td>{{item.fee_name}} </td>
                                        <td style="text-align: end;"> {{item.fee | currency:'USD'}}</td>
                                    </tr>
                                    <tr *ngIf="memberDiscount?.length ==0 && cartFee[0].discount!=0">
                                        <td> {{cartFee[0].fee_name}} Discount</td>
                                        <td style="text-align: end;"> {{cartFee[0].discount | currency:'USD'}}</td>
                                    </tr>
                                    <tr *ngIf="memberDiscount?.length ==0 && cartFee[1].discount !=0">
                                        <td> {{cartFee[1].fee_name}} Discount</td>
                                        <td style="text-align: end;"> {{cartFee[1].discount | currency:'USD'}}</td>
                                    </tr>
                                    <tr style="font-weight: bold;">
                                        <td> Total Onetime Fee </td>
                                        <td style="text-align: end;" *ngIf="!a2COneTimeFee">{{oneTimeFee |
                                            currency:'USD'}} </td>
                                        <td style="text-align: end;" *ngIf="a2COneTimeFee">{{a2COneTimeFee |
                                            currency:'USD'}} </td>
                                    </tr>
                                    <tr *ngIf="memberDiscount?.length >0">
                                        <td> Member Discount </td>
                                        <td style="text-align: end;" *ngFor="let item of cartData"> {{item.discount |
                                            currency:'USD'}}</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold;"> Total Payments Today</td>
                                        <td style="text-align: end; font-weight: bold;" *ngFor="let item of cartData">
                                            {{item.total | currency:'USD'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold;" *ngIf="monthlyContributionStartDate"> Monthly
                                            Contribution Starting {{monthlyContributionStartDate|date:'MM/dd'}}</td>
                                        <td style="text-align: end; font-weight: bold;" *ngIf="monthlyContribution">
                                            {{monthlyContribution | currency:'USD'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="" style="font-size: 19px;text-align: center;font-weight: bold;"
                            *ngIf="enrolmantType !='G2C'">Thank you for your payment </div>
                        <div class="" *ngIf="enrolmantType =='G2C'">Thank you for Enrolling in {{companyName}} program
                        </div>
                        <div style="padding: 15px 0;font-size: 15px;text-align: center;font-weight: bold;">A copy of
                            your order is emailed to you. If the email does not
                            arrive promptly, please
                            check the spam folder. </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>