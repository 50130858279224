import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
import { Router } from '@angular/router';
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  constructor( private objectService: TestService,private router:Router) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }
  goToPlans(){
    this.objectService.demoToA2c("");
    this.router.navigate(['dynamicplan']);
  }
}
