import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss']
})
export class CardPaymentComponent implements OnInit {
  cardDetails: FormGroup;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.cardDetails = this.formBuilder.group({
      cardnumber: ['', [Validators.required,Validators.maxLength(16)]],
      month: ['', [Validators.required,Validators.maxLength(2)]],
      year: ['', [Validators.required,Validators.maxLength(2)]],
      cvv: ['', [Validators.required,Validators.maxLength(4)]],
    });
  }

  get controls() { return this.cardDetails.controls; }

}
