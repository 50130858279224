<form [formGroup]="routingGroup">
  <div class="form-group row">
    <div class="col-lg-5">
      <input type="text" class="form-control"
        [ngClass]="{ 'is-invalid':  routingNumber.routing.touched && routingNumber.routing.errors }"
        placeholder="Routing Number" formControlName="routing" customize [numbersOnly]="true" [preventCopy]="true"
        [preventPaste]="true">
      <small *ngIf="routingNumber.routing.touched && routingNumber.routing.invalid" class="text-danger">Routing Number
        is required</small>
    </div>
    <div class="col-lg-5">
      <input type="text" class="form-control"
        [ngClass]="{ 'is-invalid':  routingNumber.confirmRouting.touched && routingNumber.confirmRouting.errors }"
        placeholder="Re-enter Routing Number" formControlName="confirmRouting" customize [numbersOnly]="true"
        [preventCopy]="true" [preventPaste]="true">
      <ng-container *ngIf="routingNumber.confirmRouting.errors && routingNumber.confirmRouting.touched">
        <small *ngIf="routingNumber.confirmRouting.errors.required" class="text-danger">Re-Routing is required</small>
        <small *ngIf="routingNumber.confirmRouting.errors.mustMatch" class="text-danger">Re-Routing must match
          routing</small>
      </ng-container>
    </div>
  </div>
</form>
<form [formGroup]="accountGroup">
  <div class="form-group row">
    <div class="col-lg-5">
      <input type="text" class="form-control"
        [ngClass]="{ 'is-invalid':  accountNumber.account.touched && accountNumber.account.errors }"
        placeholder="Account Number" formControlName="account" customize [numbersOnly]="true" [preventCopy]="true"
        [preventPaste]="true">
      <small *ngIf="accountNumber.account.touched && accountNumber.account.errors?.required" class="text-danger">Account Number
        is required</small>
      <small *ngIf="accountNumber.account.touched && accountNumber.account.errors?.minlength"
        class="text-danger">Account Number should be atleast 4 digits</small>
    </div>
    <div class="col-lg-5">
      <input type="text" class="form-control"
        [ngClass]="{ 'is-invalid':  accountNumber.confirmAccount.touched && accountNumber.confirmAccount.errors }"
        placeholder="Re-enter Account Number" formControlName="confirmAccount" customize [numbersOnly]="true"
        [preventCopy]="true" [preventPaste]="true">
      <ng-container *ngIf=" accountNumber.confirmAccount.touched && accountNumber.confirmAccount.errors">
        <small *ngIf="accountNumber.confirmAccount.errors.required" class="text-danger">Re-Account number is
          required</small>
        <small *ngIf="accountNumber.confirmAccount.errors.mustMatch" class="text-danger">Re-Account number must match
          Account Number </small>
      </ng-container>
    </div>
  </div>
</form>
