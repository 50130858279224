<div *ngIf="!companyContent">
  <div id="page" class="site ">
    <div data-source="gallery" class="gallery">
      <img src="assets/images/banner6.jpg">
    </div>
  </div>
  <!-- END REVOLUTION SLIDER -->
  <!-- End Header Menu End -->
  <div class="main-contain">
    <div class="container container1">
      <div id="content" class="site-content">
        <div class="row padding-">
          <div class="col-lg-12 ">
            <article id="post-7002" class="post-7002 page type-page status-publish hentry">
              <div class="entry-content">
                <div data-vc-full-width="true" data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid top-services-light mobl-mt30 vc_custom_1566537050151 vc_row-has-fill vc_rtl-columns-reverse"
                  style="position: relative; box-sizing: border-box;">
                  <div class="wpb_column vc_column_container vc_col-sm-4">
                    <div class="vc_column-inner vc_custom_1566380053149">
                      <div class="wpb_wrapper">
                        <div class="rs-services">
                          <div class="service-inner services-style-7 services-left  vc_custom_1566548693589 ">
                            <div class="services-wrap">
                              <div class="services-item">
                                <div class="services-icon">
                                  <img src="assets/images/doctor.png" alt="Rs-service">
                                </div>
                                <div class="services-desc">
                                  <h3 class="services-title services-title2 title-upper"
                                    style="padding-top: 0px;margin-bottom: 6px;">
                                    <a data-onhovercolor="#d1d1d1" data-onleavecolor="#021b79" title=""
                                      style="color: rgb(2, 27, 121); font-size: 20px;">Unlimited
                                      Primary Care</a>
                                  </h3>
                                  <p>Aenean massa cum sociis natoque penatibus et
                                    magnis dis partu rient to montes </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-4">
                    <div class="vc_column-inner vc_custom_1548234660871">
                      <div class="wpb_wrapper">
                        <div class="rs-services">
                          <div class="service-inner services-style-7 services-left  vc_custom_1566548709090 ">
                            <div class="services-wrap">
                              <div class="services-item">
                                <div class="services-icon">
                                  <img
                                    src="https://rstheme.com/products/wordpress/medvill/wp-content/uploads/2019/08/ambulance.png"
                                    alt="Rs-service">
                                </div>
                                <div class="services-desc">
                                  <h3 class="services-title services-title2 title-upper"
                                    style="padding-top: 0px;margin-bottom: 6px;">
                                    <a data-onhovercolor="#d1d1d1" data-onleavecolor="#021b79" title=""
                                      style="color: #021b79;font-size: 20px;">Urgent
                                      care</a>
                                  </h3>
                                  <p>Aenean massa cum sociis natoque penatibus et
                                    magnis dis partu rient to montes </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wpb_column vc_column_container vc_col-sm-4">
                    <div class="vc_column-inner vc_custom_1548234667014">
                      <div class="wpb_wrapper">
                        <div class="rs-services">
                          <div class="service-inner services-style-7 services-left  vc_custom_1566548724806 ">
                            <div class="services-wrap">
                              <div class="services-item">
                                <div class="services-icon">
                                  <img src="assets/images/call.png" alt="Rs-service">
                                </div>
                                <div class="services-desc">
                                  <h3 class="services-title services-title2 title-upper"
                                    style="padding-top: 0px;margin-bottom: 6px;">
                                    <a data-onhovercolor="#d1d1d1" data-onleavecolor="#021b79" title=""
                                      style="color: #021b79;font-size: 20px;">Telemedicine</a>
                                  </h3>
                                  <p>Aenean massa cum sociis natoque penatibus et
                                    magnis dis partu rient to montes </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div data-vc-full-width="true" data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid resposive-pd-btm vc_custom_1566549349555 vc_row-has-fill vc_row-o-content-middle vc_row-flex"
                  style="position: relative;  box-sizing: border-box; ">
                  <div class="services-homes4 wpb_column vc_column_container vc_col-sm-7">
                    <div class="vc_column-inner vc_custom_1566474462100">
                      <div class="wpb_wrapper">
                        <div class="rs-heading style3   ">
                          <div class="title-inner">
                            <h3 class="title title-upper"><span class="first-word"> Quality
                              </span> SERVICES</h3>
                          </div>
                          <div class="description">
                            <p>
                            </p>
                          </div>
                        </div>
                        <div class="vc_row wpb_row vc_inner vc_row-fluid home1services-style img-size-custom">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="rs-services">
                                  <div class="service-inner services-style-8  vc_custom_1566548753120 ">
                                    <div class="services-wrap">
                                      <div class="services-item">
                                        <div class="services-icon">
                                          <img src="assets/images/brain.png" alt="Rs-service">
                                        </div>
                                        <div class="services-desc">
                                          <h2 class="services-title services-title2" style="margin-bottom: 15px;">
                                            <a data-onhovercolor="#092591" data-onleavecolor="#303745" title=""
                                              style="color: rgb(48, 55, 69); font-size: 25px;">
                                              Telehealth
                                            </a>
                                          </h2>
                                          <p>At vero eos et accusamus et iusto
                                            odiodignissimos</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="rs-services">
                                  <div class="service-inner services-style-8  vc_custom_1566548770962 ">
                                    <div class="services-wrap">
                                      <div class="services-item">
                                        <div class="services-icon">
                                          <img src="assets/images/drugs.png" alt="Rs-service">
                                        </div>
                                        <div class="services-desc">
                                          <h2 class="services-title services-title2" style="margin-bottom: 15px;"><a
                                              data-onhovercolor="#092591" data-onleavecolor="#303745" title=""
                                              style="color: rgb(48, 55, 69); font-size: 25px;">Rx
                                              Discounts</a></h2>
                                          <p>At vero eos et accusamus et iusto
                                            odiodignissimos</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="vc_row wpb_row vc_inner vc_row-fluid home1services-style img-size-custom vc_custom_1566390513359">
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="rs-services">
                                  <div class="service-inner services-style-8  vc_custom_1566548793032 ">
                                    <div class="services-wrap">
                                      <div class="services-item">
                                        <div class="services-icon">
                                          <img src="assets/images/heart.png" alt="Rs-service">
                                        </div>
                                        <div class="services-desc">
                                          <h2 class="services-title services-title2" style="margin-bottom: 15px;"><a
                                              data-onhovercolor="#021b79" data-onleavecolor="#303745" title=""
                                              style="color: #303745;font-size: 25px;">Healthcare
                                              Navigation</a></h2>
                                          <p>At vero eos et accusamus et iusto
                                            odiodignissimos</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="wpb_column vc_column_container vc_col-sm-6">
                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="rs-services">
                                  <div class="service-inner services-style-8  vc_custom_1566548810791 ">
                                    <div class="services-wrap">
                                      <div class="services-item">
                                        <div class="services-icon">
                                          <img src="assets/images/visibility.png" alt="Rs-service">
                                        </div>
                                        <div class="services-desc">
                                          <h2 class="services-title services-title2" style="margin-bottom: 15px;"><a
                                              data-onhovercolor="#092591" data-onleavecolor="#303745" title=""
                                              style="color: rgb(48, 55, 69); font-size: 25px;">Bill
                                              Negotiation</a></h2>
                                          <p>At vero eos et accusamus et iusto
                                            odiodignissimos</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="home-appointment home5-appointment button-left wpb_column vc_column_container vc_col-sm-5 vc_col-has-fill">
                    <div class="vc_column-inner vc_custom_1566458498660">
                      <div class="wpb_wrapper">
                        <div class="rs-heading style4  vc_custom_1566456876759  ">
                          <div class="title-inner">
                            <h3 class="title title-upper" style="color: #021b79"><span class="first-word"> Enquiry
                              </span> Now</h3>
                          </div>
                        </div>
                        <div role="form" class="wpcf7" id="wpcf7-f3313-p7002-o1" lang="en-US" dir="ltr">
                          <div class="screen-reader-response"></div>
                          <form class="wpcf7-form" novalidate="novalidate">
                            <div class="appointment-medical">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="head_txt1"> First Name <span class="fieldMan">*</span></div>
                                      <input class="form-control" type="text">
                                    </div>
                                    <div class="col-md-6">
                                      <div class="head_txt1"> Last Name <span class="fieldMan">*</span></div>
                                      <input class="form-control" type="text">
                                    </div>
                                    <div class="col-md-12">
                                      <div class="head_txt1"> Email <span class="fieldMan">*</span></div>
                                      <input class="form-control" type="text">
                                    </div>
                                    <div class="col-md-12">
                                      <div class="head_txt1"> Phone Number <span class="fieldMan">*</span></div>
                                      <input class="form-control" type="text">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="head_txt1"> Message <span class="fieldMan">*</span></div>
                                  <textarea name="message" cols="40" rows="10" class="form-control"
                                    type="text"></textarea>
                                </div>
                              </div>
                              <!-- 
                                                            <div><button class="btn btn-sm btn-primary">Submit</button>
                                                                </div> -->
                              <div style="text-align: right;">
                                <button type="submit" class="btn btn-dark">
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div class="wpcf7-response-output wpcf7-display-none"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix">
                </div>
              </div>
              <!-- .entry-content -->
            </article>
            <!-- #post-7002 -->
          </div>
        </div>
      </div>
    </div>
  </div><!-- .main-container -->
</div>
<!--==================Fellowship ===================-->
<div *ngIf="companyContent" class="hero_section">
  <div class="section_left">
    <h5 class="main-title">
      Take Back Control of your Healthcare through the Power of the Fellowship HealthShare Community
    </h5>
    <h6 class="help-text">
      Healthcare sharing is a modern, intuitive, non-insurance approach for managing healthcare expenses.
    </h6>
  </div>
  <div class="section_right">
    <img src="assets/images/BannerImg.webp"  width="100%" height="auto" alt="Fellowship">
  </div>
</div>

<div class="section_one">
  <div class="container">
    <div class="row">

      <div class="col-md-7 md-order-2">

        <h2 class="primary-title">How it works</h2>
        <p class="description">Whether you are an individual, a family, or an employer, we have
          membership programs that will serve you well in your quest for quality healthcare.
          Fellowship
          HealthShare is a monthly Membership Community made up of people just like you, who are
          committed
          to living a healthy life! Members submit monthly Contributions that are used to share
          medical
          expenses if and when they arise.
        </p>


        <a class="readmore_link" href="#" routerLink="/how-it-works">
          Learn More <i class="fa fa-angle-right ml-2"></i>
        </a>

      </div>

      <div class="col-md-5 text-center md-order-1">

        <div class="">
          <img src="assets/images/howitworks.webp" class="img-fluid" width="100%" height="auto" alt="Image Description">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="section-devider">
  <div class="container">
    <div class="row md-text-center">
      <div class="col-md-8">
        <h3 class="secondary-title">
          Join the Fellowship Community Today and Take Back Control of your Healthcare!
        </h3>
      </div>
      <div class="col-md-4 text-center">
        <button type="submit" routerLink="/dynamicplan" class="btn btn-primary">
          Become A Member
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Our Features  -->

<div class="whyus-section">
  <div class="container">
    <div class="row">

      <div class="col-md-4">
        <div class="service-card">
          <div class="service-icon">
            <img class="img-icon" src="assets/images/icons/chat-withus.svg" alt="fellowship service icon">
          </div>
          <h5 class="service-title">
            Chat With Us
          </h5>
          <p class="service-description">
            This is where we sit down, grab a cup of coffee and dial in the details.
          </p>

          <a class="service-link" href="" routerLink="/get-in-touch">
            <i class="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>

      <div class="col-md-4">
        <div class="service-card">
          <div class="service-icon">
            <img class="img-icon" src="assets/images/icons/services.svg" alt="fellowship service icon">
          </div>
          <h5 class="service-title">
            Fellowship Programs
          </h5>
          <p class="service-description">
            Now that you know a little bit more about us, take a look at the different programs that you can choose
            from!
          </p>

          <a class="service-link" href="" routerLink="/membership">
            <i class="bi bi-arrow-right"></i>
          </a>

        </div>
      </div>

      <div class="col-md-4">
        <div class="service-card">
          <div class="service-icon">
            <img class="img-icon" src="assets/images/icons/support.svg" alt="fellowship service icon">
          </div>
          <h5 class="service-title">
            Support
          </h5>
          <p class="service-description">
            We’re here to support you whether you’re a current, previous, or prospective member! 
            <!-- Our customer service
            line is available from 8:00am–6:00pm CST Monday through Friday but if you’d like, you can write us an email
            or utilize our chat feature and someone will get back to you as soon as possible! -->
          </p>

          <a class="service-link" href="" routerLink="/membership">
            <i class="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="section-devider">
  <div class="container">
    <div class="row">

      <div class="col-md-8 service-content md-order-2">
        <h3 class="secondary-title">
          How it Works
        </h3>
        <p class="service-description">
          Fellowship HealthShare is a non-profit healthcare sharing organization. We guide our Medical Cost Sharing
          Community to share what they have to bless those in need. Our members give and receive financial support for
          medical needs. We believe that healthcare in God’s image is open to everyone, easy to enroll in, and stress
          free.
        </p>
        <a routerLink="/membership" class="mb-3 r-m"> Our
          Programs <i class="fa fa-angle-right align-middle ml-1"></i></a>
      </div>

      <div class="col-md-4 md-order-1 text-center">
        <img src="assets/images/whatwedo.png" alt="fellowship services" width="80%" height="auto" class="services-img">
      </div>
    </div>
  </div>
</div>
<div class="section-devider bg-white">
  <div class="container">

    <div class="row">
      <div class="col-md-4 md-order-1 text-center">
        <img src="assets/images/whatwedo1.png" alt="fellowship services" width="80%" height="auto" class="services-img">
      </div>
      <div class="col-md-8 service-content md-order-2">
        <h3 class="secondary-title">
          What We Do
        </h3>
        <p class="service-description">
          Our Community’s Common Ethos: As a Fellowship HealthShare member, you’ll feel confident that you’re sharing
          healthcare costs with a community that shares in your beliefs and ethics: Adult Members of Fellowship
          Healthshare profess the following Statement of Ethics to qualify for membership.
        </p>
        <a routerLink="/how-it-works" class="mb-3 r-m">
          Read our Statement of Ethics <i class="fa fa-angle-right align-middle ml-1"></i></a>
      </div>
    </div>

  </div>
</div>
<div class="section-devider ">
  <div class="container">

    <div class="row">
      <div class="col-md-8 service-content md-order-2">
        <h3 class="secondary-title">
          What We Offer
        </h3>
        <p class="service-description">
          As a nonprofit organization that has been sharing medical needs for decades, we are committed to providing
          best in class service to our members through charitable donations and medical expense sharing. The services we
          offer are not insurance but we believe that the members are better served through the financial stewardship
          that comes with a Health Sharing Community membership.
        </p>
        <a routerLink="/membership" class="mb-3 r-m">
          Learn more <i class="fa fa-angle-right align-middle ml-1"></i></a>
      </div>
      <div class="col-md-4 md-order-1 text-center">
        <img src="assets/images/whatweoffer.png" alt="fellowship services" width="80%" height="auto" class="services-img">
      </div>

    </div>

  </div>
</div>
