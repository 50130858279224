import { Component, OnInit } from '@angular/core';
import { TestService } from '../services/behaviourService';
@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss']
})
export class TermsofuseComponent implements OnInit {

  constructor(private objectService: TestService) { }

  ngOnInit(): void {
    this.objectService.pushheaderCondition("showheader");
  }


}
